import React, { useEffect, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { useParams } from 'react-router-dom';
import { sendRequest } from '../../apis/APIs';
import { handleApiResponse } from '../../apis/apiUtils';
import appSettings from '../../configs/AppConfig';
import Helper from '../../apis/Helper';
import BackButton from '../../common/BackButton';
import { AppRoutes } from '../../configs';
import { Helmet } from 'react-helmet';



const List = () => {
  const { isLoggedIn } = useSelector((state) => state.common);
  const [payments, setPayments] = useState([]);
  const [hasMorePayments, setHasMorePayments] = useState(false);
  const dispatch = useDispatch();
  const { customerId } = useParams();
  const [paginationData, setPaginationData] = useState({ perpage: appSettings.recordsPerPageTable, page: 1, totalCount: 0 });
  const [filter, setFilter] = useState({ search_string: '', status: '' });

  // State variable to control the visibility of the dropdown menu
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Function to handle the "Apply" button click
  const handleApplyClick = () => setDropdownOpen(false);

  useEffect(() => {
    if (isLoggedIn) {
      fetchPaymentHistory(filter.status);
    }
  }, [customerId]);

  const fetchPaymentHistory = async (status) => {
    try {
      dispatch(callCommonAction({ loading: true, breadCrumb: ['Agent', 'Invoice', AppRoutes.adminUserListRoute] }));
      const res = await sendRequest('/admin/user-invoices', 'POST', { customerId: customerId, status: status });
      dispatch(callCommonAction({ loading: false }));
      handleApiResponse(res);
      if (res.data.type === "success") {
        setHasMorePayments(res.data.data.hasMore);
        // Extract relevant information from invoices
        const simplifiedInvoices = res.data.data.data.map(invoice => ({
          id: invoice.id,
          account_name: invoice.account_name,
          amount_paid: invoice.amount_paid,
          amount_due: invoice.amount_due,
          amount_remaining: invoice.amount_remaining,
          billing_reason: invoice.billing_reason,
          charge: invoice.charge,
          collection_method: invoice.collection_method,
          subscription: invoice.subscription,
          created: invoice.created,
          currency: invoice.currency,
          customer: invoice.customer,
          customer_email: invoice.customer_email,
          effective_at: invoice.effective_at,
          invoice_pdf: invoice.invoice_pdf,
          paid: invoice.paid,
          number: invoice.number,
          payment_intent: invoice.payment_intent,
          period_end: invoice.lines.data[0].period.end,
          period_start: invoice.lines.data[0].period.start,
          status: invoice.status,
          subtotal: invoice.subtotal,
          total: invoice.total,
          subtotal_excluding_tax: invoice.subtotal_excluding_tax,
          total_excluding_tax: invoice.total_excluding_tax,
          // Add other properties you're interested in
        }));
        setPayments(simplifiedInvoices);
      }
    } catch (error) {
      toast.error('Something went wrong');
      dispatch(callCommonAction({ loading: false }));
    }
  };



  const Link = ({ id, children, title }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
      <a href={undefined}>{children}</a>
    </OverlayTrigger>
  );
  const Link2 = ({ id, children, title }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
      <a className="cursor-pointer text-truncate" href={undefined}>{children}</a>
    </OverlayTrigger>
  );

  const handleDownload = async (downloadLink, invoiceId) => {
    try {
      // Create an anchor element to trigger the download
      const anchor = document.createElement('a');
      anchor.href = downloadLink;
      anchor.target = '_blank'; // Open in a new tab/window
      anchor.download = `invoice_${invoiceId}.pdf`;
      anchor.click();
    } catch (error) {
      console.error('Error downloading invoice:', error);
    }
  };

  const renderTabeBody = () => {
    return payments.map((payment) => {
      // Convert timestamps to Date objects
      const startDate = new Date(payment.period_start * 1000); // Multiply by 1000 to convert seconds to milliseconds
      const endDate = new Date(payment.period_end * 1000);

      // Format dates as strings
      const formattedStartDate = startDate.toLocaleDateString();
      const formattedEndDate = endDate.toLocaleDateString();
      if (payment.subscription !== null) {
        return (
          <tr key={payment.id}>
            <td className="d-flex align-items-center">
              <div className="d-flex flex-column">
                <span>{payment.customer_email}</span>
              </div>
            </td>
            <td>{formattedStartDate} - {formattedEndDate}</td>
            <td className=''> {`$` + parseFloat(payment.total / 100).toFixed(2)}</td>
            <td className=''>
              <div className={`text-uppercase badge badge-light-${Helper.invoiceStatus[payment.status].className}`}>
                <Link title={Helper.invoiceStatus[payment.status].msg} id={payment.id}>
                  {payment.status}
                </Link>
              </div>
            </td>
            <td ><a className='ms-16' onClick={() => handleDownload(payment.invoice_pdf, payment.id)} href={undefined} title="Download Invoice" id={payment.id} download><svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 512 512"><path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" /></svg></a>
            </td>
          </tr>
        );
      }

    });

  };

  /** Applied Filter Here */
  const applyFilter = () => {
    handleApplyClick();
    setPaginationData({ ...paginationData, page: 1 });
    fetchPaymentHistory(filter.status);
  };

  const clearFilter = () => {
    handleApplyClick();
    setPaginationData({ ...paginationData, page: 1 });
    setFilter({ search_string: '', status: '' });
    fetchPaymentHistory("");
  };

  return (
    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
      <Helmet>
				<title>iQuote | Invoice History</title>
				<meta name="description" content="Description goes here" />
				<meta name="keywords" content="Game, Entertainment, Movies" />
			</Helmet>
      {/**begin::Post**/}
      <div className="post d-flex flex-column-fluid" id="kt_post">
        {/**begin::Container**/}
        <div id="kt_content_container" className="container-xxl">
          <BackButton />
          {/**begin::Card**/}
          <div className="card">
            {/**begin::Card header**/}
            <div className="card-header">
              {/**begin::Card title**/}
              <div className="card-title">
                <h3>Payment History</h3>
              </div>
              {/**begin::Card title**/}
              {/**begin::Card toolbar**/}
              {/* <div className="card-toolbar">
                <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                  <Dropdown autoClose="outside" className='me-3' show={dropdownOpen} onClose={() => setDropdownOpen(false)}>
                    <Dropdown.Toggle onClick={() => setDropdownOpen(!dropdownOpen)} className='btn btn-light btn-active-light-primary' id="dropdown-basic">
                      <i className="ki-duotone ki-filter fs-2">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>Filter
                    </Dropdown.Toggle>
                    <Dropdown.Menu >
                      <Dropdown.Item className='p-0'>
                        <div className="menu menu-sub menu-sub-dropdown w-300px w-md-325px d-block" data-kt-menu="true">
                          <div className="px-7 py-5">
                            <div className="fs-5 text-gray-900 fw-bold">Filter Options</div>
                          </div>
                          <div className="separator border-gray-200"></div>
                          <div className="px-7 py-5" data-kt-user-table-filter="form">
                            <div className="mb-10">
                              <label className="form-label fs-6 fw-semibold">Status:</label>
                              <select className="form-select form-select-solid fw-bold" value={filter.status} onChange={(e) => setFilter({ ...filter, status: e.target.value })}>
                                <option value="all" >All</option>
                                <option value="draft">Draft</option>
                                <option value="open">Open</option>
                                <option value="paid">Paid</option>
                                <option value="void">Void</option>
                                <option value="uncollectible">Uncollectible</option>
                              </select>
                            </div>
                            <div className="d-flex justify-content-end">
                              <button type="reset" className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6" data-kt-menu-dismiss="true" data-kt-user-table-filter="reset" onClick={clearFilter}>Reset</button>
                              <button type="submit" className="btn btn-primary fw-semibold px-6" data-kt-menu-dismiss="true" data-kt-user-table-filter="filter" onClick={applyFilter}>Apply</button>
                            </div>
                          </div>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div> */}
            </div>
            {/**end::Card header**/}
            {/**begin::Card body**/}
            <div className="card-body table-frame py-4">
              {/**begin::Table**/}
              <div className='table-responsive'>
              <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users">
                <thead>
                  <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                    <th className="min-w-130px">Email</th>
                    <th className="min-w-130px">Period</th>
                    <th className="min-w-130px">Amount</th>
                    <th className="min-w-130px w-130">Status</th>
                    <th className="text-center min-w-130px w-130">Download</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 fw-semibold">
                  {payments.length > 0 ? renderTabeBody() : <tr>
                    <td colSpan={7} className='text-center'>No result found!</td>
                  </tr>}
                </tbody>
              </table>
              </div>
              {/**end::Table**/}
            </div>
            {/**end::Card body**/}
          </div>
          {/**end::Card**/}
        </div>
        {/**end::Container**/}
      </div>
      {/**end::Post**/}
    </div >
  );
};

export default List;
