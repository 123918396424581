import STRINGS from '../../common/strings/strings';
import Helper from '../../apis/Helper';

export function validateRegisterFormData(userDetail, fieldName, isValidPhoneNumber) {
    const { terms, display_name, first_name, last_name, email, phonenumber, password, password_confirmation } = userDetail;
    let errorMsg = ''; let errors = {}; let formVaildCheck = true;

    if (display_name === '' && (fieldName == 'display_name' || fieldName == '')) {
        errorMsg = display_name === '' ? STRINGS.displayNameReq : '';
        errors.display_name = errorMsg;
        formVaildCheck = false;
    }
    if (last_name === '' && (fieldName == 'last_name' || fieldName == '')) {
        errorMsg = last_name === '' ? STRINGS.lastNameReq : '';
        errors.last_name = errorMsg;
        formVaildCheck = false;
    }
    if (first_name === '' && (fieldName == 'first_name' || fieldName == '')) {
        errorMsg = first_name === '' ? STRINGS.firstNameReq : '';
        errors.first_name = errorMsg;
        formVaildCheck = false;
    }
    if ((fieldName == 'email' || fieldName == '') && (!Helper.validateEmail(email))) {
        errorMsg = email == '' ? STRINGS.emailReq : STRINGS.invalidEmail;
        formVaildCheck = false;
        errors.email = errorMsg;
    }
    if ((fieldName == 'phonenumber' || fieldName == '') && (!isValidPhoneNumber(phonenumber) )) {
        errorMsg = "Please enter a valid phone number";
        errors.phonenumber = errorMsg;
        formVaildCheck = false;
    }
    
    if ((fieldName == 'terms' || fieldName == '') && (!terms)) {
        errors.terms = STRINGS.termsReq;
        formVaildCheck = false;
    }

    if ((fieldName == 'password' || fieldName == '') && (!Helper.validatePassword(password))) {
        errorMsg = password == '' ? STRINGS.passwordReq : STRINGS.invalidPassword;
        errors.password = errorMsg;
        formVaildCheck = false;
    }

    if ((fieldName == 'password_confirmation' || fieldName == '') && !Helper.validatePassword(password_confirmation)) {
        errorMsg = password_confirmation == '' ? STRINGS.confirmPasswordReq : STRINGS.invalidConfirmPassword;
        errors.password_confirmation = errorMsg;
        formVaildCheck = false;
    }

    if (password_confirmation !== '' && password !== '' && (fieldName == 'password_confirmation' || fieldName == '')) {
        if (password_confirmation !== password) {
            errors.password_confirmation = STRINGS.passwordMissMatch;
            formVaildCheck = false;
        }
    }
    return { formVaildCheck: formVaildCheck, errors: errors };
}