import React, { useEffect, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { sendRequest } from '../../../apis/APIs';
import { handleApiResponse } from '../../../apis/apiUtils';
import appSettings from '../../../configs/AppConfig';
import Helper from '../../../apis/Helper';
import { Helmet } from 'react-helmet';
import Pagination from '../../../common/Pagination/pagination';
import { useNavigate } from 'react-router-dom';
import appRoutes from '../../../configs/AppRoutes';

const List = () => {
  const dispatch = useDispatch();
  const [paginationData, setPaginationData] = useState({ perpage: appSettings.recordsPerPageTable, page: 1, totalCount: 0 });
  const [filter, setFilter] = useState({ search_string: '', status: '' });
  const [subRepList, setSubRepList] = useState([]);
  const navigate = useNavigate();

  const Link = ({ id, children, title }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
      <a href={undefined}>{children}</a>
    </OverlayTrigger>
  );

  useEffect(() => {
    if (filter.search_string.length > 2 || filter.search_string === "") {
      getSubscriptionReport(1, filter.status);
    }
  }, [filter.search_string]);

  const getSubscriptionReport = async (page, status) => {
    try {
      dispatch(callCommonAction({ loading: true, breadCrumb: ['Subscription', 'Report'] }));
      const res = await sendRequest(`/subscription/report`, 'GET', { page: page, limit: paginationData.perpage, search_string: filter.search_string, status: status });
      dispatch(callCommonAction({ loading: false }));
      handleApiResponse(res);
      if (res.data.type === "success") {
        setSubRepList(res.data.data.docs);
        setPaginationData({ ...paginationData, totalCount: res.data.data.totalDocs, page: res.data.data.page });
      }
    } catch (error) {
      toast.error('Something went wrong');
      dispatch(callCommonAction({ loading: false }));
    }
  };


  const renderTabeBody = () => {
    return subRepList.map((payment) => {
      // Convert timestamps to Date objects
      const startDate = new Date(payment.current_period_start * 1000); // Multiply by 1000 to convert seconds to milliseconds
      const endDate = new Date(payment.current_period_end * 1000);

      // Format dates as strings
      const formattedStartDate = startDate.toLocaleDateString();
      const formattedEndDate = endDate.toLocaleDateString();
      return (
        <tr key={payment.id}>
          <td className="d-flex align-items-center">
            {/**begin:: Avatar **/}
            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                <div className="symbol-label">
                  <img src={payment.created_by && payment.created_by.user_image ? process.env.REACT_APP_ASSET_ENDPOINT + '/' + payment.created_by.user_image : '/assets/media/avatars/blank.png'} alt={payment.created_by?.fullname} className="w-100" />
                </div>
            </div>
            {/**end::Avatar**/}
            {/**begin::User details**/}
            <div className="d-flex flex-column">
              <a href={undefined} className="text-gray-800 text-hover-primary mb-1" onClick={() => navigate(appRoutes.adminUserDetailRoute + '/' + payment.created_by._id)}>{payment.created_by?.first_name + ' ' + payment.created_by?.last_name}</a>
              <span>{payment.created_by?.email}</span>
            </div>
            {/**begin::User details**/}
          </td>
          <td>{formattedStartDate} - {formattedEndDate}</td>
          <td className=''>{`$`+parseFloat(payment.unit_amount).toFixed(2)}</td>
          <td className='text-end'><div className={`text-uppercase badge badge-light-${Helper.invoiceStatus[payment.stripe_status].className}`}>
            <Link title={Helper.invoiceStatus[payment.stripe_status].msg} id={payment.id}>
              {payment.stripe_status}
            </Link>
          </div></td>
        </tr>
      );

    });

  };

  return (
    <>
      {/*begin::Content*/}
      <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
        <Helmet>
          <title>iQuote | Payment List</title>
          <meta name="description" content="Description goes here" />
          <meta name="keywords" content="Game, Entertainment, Movies" />
        </Helmet>
        {/*begin::Post*/}
        <div className="post d-flex flex-column-fluid" id="kt_post">
          {/*begin::Container*/}
          <div id="kt_content_container" className="container-xxl">

            {/*begin::Row*/}
            <div className="row g-10 mb-12 justify-content-center">
              {/*begin::Col*/}
              <div className="col-xl-4 ">
                <div className="d-flex h-100 align-items-center">
                  {/*begin::Option*/}
                  <div className="bg-light bg-opacity-75 border d-flex flex-center flex-column px-10 py-5 rounded-3 w-100">
                    {/*begin::Heading*/}
                    <div className="text-center">
                      {/*begin::Title*/}
                      <h1 className="text-gray-900 fw-bolder fs-3">
                        Startup Plan
                      </h1>

                      <div className="text-center">
                        <span className="mb-2 text-primary">$</span>
                        <span
                          className="fs-3x fw-bold text-primary"
                          data-kt-plan-price-month={39}
                          data-kt-plan-price-annual={399}
                        >
                          37
                        </span>
                        <span className="fs-7 fw-semibold opacity-50">
                          /<span data-kt-element="period">Mon</span>
                        </span>
                      </div>
                      {/*end::Price*/}
                    </div>
                    {/*end::Heading*/}
                    {/*begin::Features*/}

                    {/*end::Features*/}

                  </div>
                  {/*end::Option*/}
                </div>
              </div>
              {/*end::Col*/}
            </div>
            {/*end::Row*/}

            {/*begin::Pricing card*/}
            <div className="card" id="kt_pricing">
              {/**begin::Card header**/}
              <div className="card-header">
                {/**begin::Card title**/}
                <div className="card-title">
                  {/**begin::Search**/}
                  <div className="d-flex align-items-center position-relative my-1">
                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <input type="text" data-kt-user-table-filter="search" value={filter.search_string} onChange={(e) => setFilter({ ...filter, search_string: e.target.value })} className="form-control form-control-solid w-250px ps-13 border" placeholder="Search user" />
                  </div>
                </div>
                {/**begin::Card title**/}
              </div>
              {/**end::Card header**/}
              {/**begin::Card body**/}
              <div className="card-body table-frame py-4">
                {/**begin::Table**/}
                <div className='table-responsive'>
                <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users">
                  <thead>
                    <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                      <th className="min-w-125px">Email</th>
                      <th className="min-w-125px">Period</th>
                      <th className="min-w-125px">Amount</th>
                      <th className="min-w-125px text-end">Status</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-600 fw-semibold">
                    {subRepList.length > 0 ? renderTabeBody() : <tr>
                      <td colSpan={7} className='text-center'>No result found!</td>
                    </tr>}
                  </tbody>
                </table>
                </div>
                {/**end::Table**/}
                <Pagination className="pagination-bar" currentPage={paginationData.page} totalCount={paginationData.totalCount}
                  pageSize={paginationData.perpage} onPageChange={page => getSubscriptionReport(page, filter.status)}
                />
              </div>
              {/**end::Card body**/}
            </div>
            {/*end::Pricing card*/}
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Post*/}
      </div>
      {/*end::Content*/}
    </>

  );
};


export default List;