import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import appRoutes from '../configs/AppRoutes';
export default function NoRouteFound() {
    const navigate = useNavigate();
    const isAdminUrl = window.location.href.includes('admin');
    const { isLoggedIn, isAdmin } = useSelector((state) => state.common);

    useEffect(() => {
        if (isLoggedIn) {
            if (isAdmin) {
                window.location.href = process.env.REACT_APP_FRONTEND + appRoutes.adminDashboardRoute;
            }else{
                window.location.href = process.env.REACT_APP_FRONTEND + appRoutes.dashboardRoute;
            }
        } else {
            navigate(isAdminUrl ? appRoutes.adminLoginRoute : appRoutes.loginRoute);
        }
    }, []);

    return null;
}
