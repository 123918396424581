import STRINGS from '../../common/strings/strings';
import Helper from '../../apis/Helper';

export function validateFormData(formType, fieldName, formData, isValidPhoneNumber) {
    let errorMsg = '';
    let errors = {};
    let formVaildCheck = true;
    let zipCodePattern = /^\d{5}(?:[-]\d{4})?$/;

    //console.log('fieldName', fieldName, 'formType', formType, 'formData', formData);

    if (formType === 'form1') {
        let { first_name, last_name, email, date_of_birth } = formData[formType];
        if (((fieldName == 'first_name' || fieldName == "") && first_name == '')) {
            errorMsg = first_name === '' ? STRINGS.firstNameReq : '';
            errors['first_name'] = errorMsg;
            formVaildCheck = false;
        }
        if (((fieldName == 'last_name' || fieldName == "") && last_name == '')) {
            errorMsg = last_name === '' ? STRINGS.lastNameReq : '';
            errors['last_name'] = errorMsg;
            formVaildCheck = false;
        }

        if (((fieldName == 'email' || fieldName == "") && (email == '' || !Helper.validateEmail(email)))) {
            errorMsg = email === "" ? STRINGS.emailReq : STRINGS.invalidEmail;
            formVaildCheck = false;
            errors['email'] = errorMsg;
        }
        if (((fieldName == 'date_of_birth' || fieldName == "") && date_of_birth == '')) {
            errorMsg = date_of_birth == '' ? STRINGS.dateOfBirthReq : '';
            errors['date_of_birth'] = errorMsg;
            formVaildCheck = false;
        }
    } else if (formType === 'form2') {
        let { phone_number, zip_code, price } = formData[formType];
        //console.log('test zip', zipCodePattern.test(zip_code));
        
        if ((fieldName == 'phone_number' || fieldName == '') && (!isValidPhoneNumber(phone_number) )) {
            errorMsg = "Please enter a valid phone number";
            errors.phone_number = errorMsg;
            formVaildCheck = false;
        }
        if (((fieldName == 'zip_code' || fieldName == "") && (!zipCodePattern.test(zip_code)))) {
            errorMsg = zip_code === '' ? STRINGS.zipCodeReq : STRINGS.invalidZipCode;
            errors['zip_code'] = errorMsg;
            formVaildCheck = false;
        }
    } else if (formType === 'form3') {
        let { policies } = formData[formType];
        if (policies.length === 0) {
            errors['policies'] = STRINGS.policiesReq;
            formVaildCheck = false;
        }
    }

    //console.log('formVaildCheck', formVaildCheck, 'errors', errors);


    return { formVaildCheck: formVaildCheck, errors: errors };
}