import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendRequest } from '../../../apis/APIs';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import 'react-toastify/dist/ReactToastify.css'; // import first
import { toast } from 'react-toastify';
import { handleApiResponse } from '../../../apis/apiUtils';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import appSettings from '../../../configs/AppConfig';
import Pagination from '../../../common/Pagination/pagination';
import appRoutes from '../../../configs/AppRoutes';

const Dashboard = () => {
    const { isLoggedIn, isAdmin } = useSelector((state) => state.common);
    const [dashboardData, setDashboardData] = useState({});
    const [paginationData, setPaginationData] = useState({ perpage: appSettings.recordsPerPageTable, page: 1, totalCount: 0 });
    const [NewsList, setNewsList] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /** Only Load Once */
    useEffect(() => {
        if (isLoggedIn) {
            getDashboard();
        }
    }, []);

    useEffect(() => {
        getNewsList();
    }, [paginationData.page]);

    /** Get News List For This Project */
    const getNewsList = async () => {
        try {
            dispatch(callCommonAction({ loading1: true }));
            const res = await sendRequest('/news/list', 'GET', { page: paginationData.page, limit: paginationData.perpage });
            dispatch(callCommonAction({ loading1: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                setNewsList(res.data.data.docs);
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading1: false }));
        }
    };

    /** Get Dashboard Data **/
    const getDashboard = async () => {
        try {
            dispatch(callCommonAction({ loading: true, breadCrumb: ['Dashboards', 'Default'] }));
            const res = await sendRequest(`/user/dashboard`, 'GET');
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                setDashboardData(res.data.data);
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    /** Render News Table Body */
    const renderNewsTabeBody = () => {
        return NewsList.map((news) => {
            return (
                <tr key={news.id}>
                    <td><img style={{ width: '80px' }} src={`${process.env.REACT_APP_ASSET_ENDPOINT}/${news.user_image}`} /></td>
                    <td>{news.name}</td>
                    <td><div dangerouslySetInnerHTML={{ __html: news.des }} /></td>
                    <td className="text-end">
                        <span className="badge badge-light-primary fs-8 fw-bold" onClick={() => navigate(appRoutes.newsDetailRoute + '/' + news.id)}>
                            <i className="fs-2 ki-duotone ki-eye text-primary cursor-pointer">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                            </i>
                        </span>

                    </td>
                </tr>
            );
        });

    };

    /** Render Latest Ten Leads On Dashboard */
    const renderTabeBody = () => {
        return dashboardData.leadList.docs.map((lead) => {
            let badgeCls = lead.status === 1 ? 'success' : 'danger';
            return (
                <tr key={lead.id}>
                    <td className='min-w-125px'><span>{lead.customer_fullname}</span></td>
                    <td className='min-w-125px'><span>{lead.email}</span></td>
                    <td className='min-w-125px'><span>{lead.phone}</span></td>
                    <td className='min-w-125px'><span>{lead.lowestPrice + '-' + lead.heighestPrice}</span></td>
                    {/* <td className='min-w-125px'>{lead.offers.map((item) => item.name).filter(Boolean).join(', ')}</td> */}
                    {/* <td className='min-w-125px'>{moment(new Date(lead.created_at)).format('MM/DD/YYYY')}</td> */}
                    {/* <td className='min-w-125px'><span className={`text-uppercase badge badge-light-${badgeCls}`}>{lead.status_name}</span></td> */}
                    <td className='min-w-125px text-center'>
                        <span className="badge badge-light-primary fs-8 fw-bold" onClick={() => navigate(appRoutes.leadDetailRoute + '/' + lead._id)}>
                            <i className="fs-2 ki-duotone ki-eye text-primary cursor-pointer">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                            </i>
                        </span>
                    </td>
                </tr>
            );
        });

    };

    return (
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <Helmet>
                <title>iQuote | Dashboard</title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>
            {/**begin::Post**/}
            <div className="post d-flex flex-column-fluid" id="kt_post">
                {/**begin::Container**/}
                <div id="kt_content_container" className="container-xxl">


                    <div className="row mb-8" >

                        <div className="col-xl-4 mb-8 mb-xl-0">

                            {/**begin::List Widget 3**/}
                            <div className="card h-100 mb-xl-8">
                                <div className="p-4 border-bottom">
                                    {/**begin::Statistics Widget 5**/}
                                    <a href={undefined} onClick={() => navigate(appRoutes.leadListRoute)} className="cursor-pointer card bg-body-tertiary hoverable card-xl-stretch">
                                        {/**begin::Body**/}
                                        <div className="card-body ">
                                            <i className="ki-duotone ki-chart-simple text-primary fs-2x ms-n1">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                                <span className="path4"></span>
                                            </i>
                                            <div className="text-gray-900 fw-bold fs-2 mb-2 mt-5">Leads report</div>
                                        </div>
                                        {/**end::Body**/}
                                    </a>
                                    {/**end::Statistics Widget 5**/}
                                </div>
                                {/**begin::Header**/}
                                <div className="card-header border-0">
                                    <h3 className="card-title fw-bold text-gray-900">Checklist of setup process</h3>
                                    <div className="card-toolbar">
                                    </div>
                                </div>
                                {/**end::Header**/}
                                {/**begin::Body**/}
                                <div className="card-body pt-2">
                                    <div className="d-flex align-items-center mb-8">
                                        <span className="bullet bullet-vertical h-40px bg-success"></span>
                                        <div className="form-check form-check-custom form-check-solid mx-5">
                                            <input className="form-check-input" type="checkbox" checked={dashboardData.userData?.policies?.length !== 0} disabled />
                                        </div>
                                        <div className="flex-grow-1">
                                            <span className="text-gray-800 text-hover-primary fw-bold fs-6">Selected insurance types</span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mb-8">
                                        <span className="bullet bullet-vertical h-40px bg-primary"></span>
                                        <div className="form-check form-check-custom form-check-solid mx-5">
                                            <input className="form-check-input" type="checkbox" checked={dashboardData.userData?.crm_settings?.api_key !== ''} disabled />
                                        </div>
                                        <div className="flex-grow-1">
                                            <span className="text-gray-800 text-hover-primary fw-bold fs-6">Integration with CRM</span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mb-8">
                                        <span className="bullet bullet-vertical h-40px bg-warning"></span>
                                        <div className="form-check form-check-custom form-check-solid mx-5">
                                            <input className="form-check-input" type="checkbox" checked={dashboardData.userData?.carriers?.length !== 0} disabled />
                                        </div>
                                        <div className="flex-grow-1">
                                            <span className="text-gray-800 text-hover-primary fw-bold fs-6">Carrier setup</span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mb-8">
                                        <span className="bullet bullet-vertical h-40px bg-danger"></span>
                                        <div className="form-check form-check-custom form-check-solid mx-5">
                                            <input className="form-check-input" type="checkbox" checked={dashboardData.userData?.offers?.length !== 0} disabled />
                                        </div>
                                        <div className="flex-grow-1">
                                            <span className="text-gray-800 text-hover-primary fw-bold fs-6">Offers selected</span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mb-8">
                                        <span className="bullet bullet-vertical h-40px bg-maroon"></span>
                                        <div className="form-check form-check-custom form-check-solid mx-5">
                                            <input className="form-check-input" type="checkbox" disabled checked={dashboardData.userData?.insurance_url !== ''} />
                                        </div>
                                        <div className="flex-grow-1">
                                            <span className="text-gray-800 text-hover-primary fw-bold fs-6">Insurance agent URL</span>
                                        </div>
                                    </div>
                                </div>
                                {/**end::Body**/}
                            </div>
                            {/**end:List Widget 3**/}
                        </div>
                        <div className="col-xl-8 ">
                            {/**begin::Tables Widget 5**/}
                            <div className="card h-100">
                                {/**begin::Header**/}
                                <div className="card-header">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label fw-bold fs-3 mb-1">Latest leads</span>
                                    </h3>
                                </div>
                                {/**end::Header**/}
                                {/**begin::Body**/}
                                <div className="card-body  py-3">
                                <div className='table-responsive'>
                                    <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                                        {/**begin::Table head**/}
                                        <thead>
                                            <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0 border-0">
                                                <th className="min-w-125px">Customer Name</th>
                                                <th className="min-w-125px">Customer Email</th>
                                                <th className="min-w-125px">Customer Phone</th>
                                                <th className="min-w-125px">Price Range ($)</th>
                                                <th className="min-w-125px text-center">Action</th>
                                            </tr>
                                        </thead>
                                        {/**end::Table head**/}
                                        {/**begin::Table body**/}
                                        <tbody className="text-gray-600 fw-semibold">
                                            {dashboardData.leadList && dashboardData.leadList.docs.length > 0 ? renderTabeBody() : <tr>
                                                <td colSpan={5} className='text-center'>No result found!</td>
                                            </tr>}
                                        </tbody>
                                        {/**end::Table body**/}
                                    </table>
                                </div>
                                </div>
                                {/**end::Body**/}


                            </div>
                            {/**end::Tables Widget 5**/}
                        </div>
                    </div>
                    <div className="" >
                        <div className="col-xl-12">
                            {/**begin::Tables Widget 5**/}
                            <div className="card card-xl-stretch mb-5 mb-xl-12">
                                {/**begin::Header**/}
                                <div className="card-header">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label fw-bold fs-3 mb-1">iQuote news</span>
                                    </h3>
                                </div>
                                {/**end::Header**/}
                                {/**begin::Card body For News**/}
                                <div className="card-body py-0">
                                    {/**begin::Table wrapper**/}
                                    <div className="table-responsive">
                                        {/**begin::Table**/}
                                        <table className="table align-middle table-row-dashed fw-semibold text-gray-600 fs-6 gy-5 news-table" id="kt_table_customers_logs">
                                            {/**begin::Table body**/}
                                            <thead>
                                                <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                                    <th className="min-w-50px">Images</th>
                                                    <th className="min-w-200px">Title</th>
                                                    <th className="min-w-200px">Description</th>
                                                    {/* <th className="min-w-125px text-center">Status</th> */}
                                                    <th className="min-w-50px text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {NewsList.length > 0 ? renderNewsTabeBody() : <tr>
                                                    <td colSpan={4} className='text-center'>No result found!</td>
                                                </tr>}
                                            </tbody>
                                            {/**end::Table body**/}
                                        </table>
                                        {/**end::Table**/}
                                        
                                    </div>
                                    <div className='pag mb-4'>
                                            <Pagination className="pagination-bar" currentPage={paginationData.page} totalCount={paginationData.totalCount}
                                                pageSize={paginationData.perpage} onPageChange={page => setPaginationData({ ...paginationData, page: page })}
                                            />
                                        </div>
                                    {/**end::Table wrapper**/}
                                </div>
                                {/**end::Card body**/}
                            </div>
                        </div>
                    </div>
                </div>
                {/**end::Container**/}
            </div>
            {/**end::Post**/}
        </div>
    );
};

export default Dashboard;
