import React, { useState, forwardRef, useImperativeHandle } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const CheckoutForm = forwardRef((props, ref) => {
    const stripe = useStripe();
    const elements = useElements();
    const [cardError, setCardError] = useState(null);

    useImperativeHandle(ref, () => ({
        async handleCardElement() {
            if (!stripe || !elements) {
                return;
            }

            const cardElement = elements.getElement(CardElement);
            // Create a payment method
            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
            });

            if (error) {
                console.error(error);
                return null;
            }
            return paymentMethod;
        },
    }));

    const handleCardChange = (event) => {
        if (event.error) {
            setCardError(event.error.message);
        } else {
            setCardError(null);
        }
    };
    return (
        <>
            <label className="form-label fw-bolder text-gray-900 fs-6">
                Card Element
            </label>
            <CardElement onChange={handleCardChange} />
            {cardError && <div style={{ color: "red" }}>{cardError}</div>}
        </>
    );
});

export default CheckoutForm;
