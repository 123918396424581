import STRINGS from '../../common/strings/strings';
import Helper from '../../apis/Helper';

export function validateUserFormData(userDetail, fieldName, isValidPhoneNumber) {
    const {  display_name, first_name, last_name, email, phonenumber } = userDetail;
    let errorMsg = ''; let errors = {}; let formVaildCheck = true;

    if (display_name === '' && (fieldName == 'display_name' || fieldName == '')) {
        errorMsg = display_name === '' ? STRINGS.displayNameReq : '';
        errors.display_name = errorMsg;
        formVaildCheck = false;
    }
    if (last_name === '' && (fieldName == 'last_name' || fieldName == '')) {
        errorMsg = last_name === '' ? STRINGS.lastNameReq : '';
        errors.last_name = errorMsg;
        formVaildCheck = false;
    }
    if (first_name === '' && (fieldName == 'first_name' || fieldName == '')) {
        errorMsg = first_name === '' ? STRINGS.firstNameReq : '';
        errors.first_name = errorMsg;
        formVaildCheck = false;
    }
    if ((fieldName == 'email' || fieldName == '') && (!Helper.validateEmail(email))) {
        errorMsg = email == '' ? STRINGS.emailReq : STRINGS.invalidEmail;
        formVaildCheck = false;
        errors.email = errorMsg;
    }
    if ((fieldName == 'phonenumber' || fieldName == '') && (!isValidPhoneNumber(phonenumber) )) {
        errorMsg = "Please enter a valid phone number";
        errors.phonenumber = errorMsg;
        formVaildCheck = false;
    }
    return { formVaildCheck: formVaildCheck, errors: errors };
}