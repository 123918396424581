import STRINGS from '../../../common/strings/strings';
import Helper from '../../../apis/Helper';

export function validateOfferFormData(offerDetail, fieldName) {
    const { name } = offerDetail;
    let errorMsg = ''; let errors = {}; let formVaildCheck = true;
    if (name === '' && (fieldName == 'name' || fieldName == '')) {
        errorMsg = name === '' ? STRINGS.nameReq : '';
        errors.name = errorMsg;
        formVaildCheck = false;
    }
    return { formVaildCheck: formVaildCheck, errors: errors };
}