import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css'; // import first
import { toast } from 'react-toastify'; // then this
import { validatePolicyFormData } from './Validation';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { sendRequest } from '../../../apis/APIs';
import { handleApiResponse } from '../../../apis/apiUtils';
import { useDispatch, useSelector } from 'react-redux';
import appRoutes from '../../../configs/AppRoutes';
import BackButton from '../../../common/BackButton';
import { Helmet } from 'react-helmet';
import ManagePolicy from './ManagePolicy';

const AddEdit = () => {
	const params = useParams();
	const navigate = useNavigate();
	const [policyForm, setPolicyForm] = useState({ name: "", des: "", sort: "" });
	const [errorsInfo, setErrorsInfo] = useState({ name: "", des: "" });
	const dispatch = useDispatch();
	const { loading } = useSelector((state) => state.common);

	useEffect(() => {
		if (params.id !== undefined) getAddEditFormData();
		dispatch(callCommonAction({ breadCrumb: ['Policy', params.id === undefined ? 'Add Form' : 'Edit Form', appRoutes.adminPolicyListRoute] }));
	}, [""]);


	const getAddEditFormData = async () => {
		try {
			dispatch(callCommonAction({ loading: true }));
			const res = await sendRequest('/policy/form/' + params.id, 'GET');
			dispatch(callCommonAction({ loading: false }));
			handleApiResponse(res);
			if (res.data.type === "success") {
				setPolicyForm(res.data.data);
			}
		} catch (error) {
			toast.error('Something went wrong');
			dispatch(callCommonAction({ loading: false }));
		}
	};

	/** Handle Change ****/
	const handleChange = (e) => {
		policyForm[e.target.name] = e.target.value;
		setPolicyForm({ ...policyForm, [e.target.name]: e.target.value });
		checkFormIsValid(e.target.name);
	};

	/** Validation implemented here */
	const checkFormIsValid = async (fieldName) => {
		const res = validatePolicyFormData(policyForm, fieldName);
		setErrorsInfo(res.errors);
		return res.formVaildCheck;
	};

	/** Submit Form Data */
	const formSubmit = async (e) => {
		e.preventDefault();
		if (await checkFormIsValid('')) {
			try {
				dispatch(callCommonAction({ loading: true }));
				var url = params.id === undefined ? '/policy/create' : '/policy/update/' + params.id;
				const res = await sendRequest(url, 'POST', policyForm);
				dispatch(callCommonAction({ loading: false }));
				handleApiResponse(res);
				if (res.data.type === "success") {
					navigate(appRoutes.adminPolicyListRoute);
				}
			} catch (error) {
				toast.error('Something went wrong');
				dispatch(callCommonAction({ loading: false }));
			}
		}
	};


	return (
		<>
			<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
				<Helmet>
					<title>iQuote | Policy Form</title>
					<meta name="description" content="Description goes here" />
					<meta name="keywords" content="Game, Entertainment, Movies" />
				</Helmet>
				<div id="kt_content_container" className="container-xxl">
					<BackButton />
					<div className="card">
						<div className="card-header" id="kt_chat_contacts_header">
							<div className="card-title">
								<i className="ki-duotone ki-badge fs-1 me-2">
									<span className="path1"></span>
									<span className="path2"></span>
									<span className="path3"></span>
									<span className="path4"></span>
									<span className="path5"></span>
								</i>
								<h2>Policy Form</h2>
							</div>
						</div>
						<div className="card-body pt-5">
							<form className="form fv-plugins-bootstrap5 fv-plugins-framework" noValidate onSubmit={formSubmit}>
								<div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
									<div className="col">
										<div className="fv-row mb-7 fv-plugins-icon-container">
											<label className="fs-6 fw-semibold form-label mt-3">
												<span className="required">Name</span>
											</label>
											<input type="text" onChange={handleChange} value={policyForm.name} className="form-control form-control-solid" name="name" />
											<div className="fv-plugins-message-container">
												<div className="fv-help-block">
													<span role="alert">{errorsInfo.name}</span>
												</div>
											</div>

										</div>
										<div className="fv-row mb-7 fv-plugins-icon-container">
											<button type="submit" id="kt_sign_up_submit"
												className="btn btn-primary me-2 btn-sm" disabled={loading ? true : false}
											>
												{
													loading
														?
														<span className="indicator-progress" style={{ display: 'block' }}>
															Please wait...
															<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
														</span>
														:
														<span className="indicator-label">Save</span>
												}

											</button>
										</div>

									</div>
								</div>
							</form>
						</div>

					</div>
				</div>
			</div>
			{params.id !== undefined ? <ManagePolicy /> : null}
		</>


	);
};
export default AddEdit;
