import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { useDispatch, useSelector } from 'react-redux';
import { sendRequest } from '../../../apis/APIs';
import { handleApiResponse } from '../../../apis/apiUtils';
import { toast } from 'react-toastify';
import appRoutes from '../../../configs/AppRoutes';
import { Helmet } from 'react-helmet';

const Detail = () => {
    const params = useParams();
    const { isAdmin } = useSelector((state) => state.common);
    const dispatch = useDispatch();
    const [newsForm, setNewsForm] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(callCommonAction({ breadCrumb: ['News', 'Detail', isAdmin ? appRoutes.adminNewsListRoute : appRoutes.dashboardRoute] }));
        getNewsDetail();
    }, []);

    const getNewsDetail = async () => {
        try {
            dispatch(callCommonAction({ loading: true }));
            const res = await sendRequest('/news/form/' + params.id, 'GET');
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                setNewsForm(res.data.data);
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    return (
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <Helmet>
                <title>iQuote | News Detail</title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>
            <div id="kt_content_container" className="container-xxl">
                {/**begin::Card header**/}
                <div className="card-header">
                        {/**begin::Card title**/}
                        <div className="align-items-center card-title d-flex justify-content-between mb-2">
                            <span className="btn btn-primary btn-sm " onClick={() => navigate(-1)}><i className="ki-duotone ki-left"></i>Back</span>
                        </div>
                        {/**end::Card title**/}
                    </div>
                    {/**end::Card header**/}
                {/**end::Card header**/}
                <div className="card">
                    {/**begin::Body**/}
                    <div className="card-body p-lg-17">
                        {/**begin::About**/}
                        <div className="row">
                            {/**begin::Wrapper**/}
                            <div className="mb-10 col-md-4">
                                {/**begin::Top**/}
                               
                                {/**end::Top**/}
                                {/**begin::Overlay**/}
                                <div className="overlay">
                                    {/**begin::Image**/}
                                    <img className="w-100 card-rounded" src={newsForm?.user_image ? process.env.REACT_APP_ASSET_ENDPOINT + '/' + newsForm.user_image : 'url("assets/media/avatars/blank.png")'} alt="" />
                                    {/**end::Image**/}
                                    {/**begin::Links**/}
                                    {/* <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                            <a href="pages/pricing.html" className="btn btn-primary">Pricing</a>
                                            <a href="pages/careers/apply.html" className="btn btn-light-primary ms-3">Join Us</a>
                                        </div> */}
                                    {/**end::Links**/}
                                </div>
                                {/**end::Container**/}
                            </div>
                            {/**end::Wrapper**/}
                            {/**begin::Description**/}
                            <div className="fs-5 fw-semibold text-gray-600 col-md-8">
                            <div className="mb-5">
                                    {/**begin::Title**/}
                                    <h3 className="fs-2hx text-gray-900 mb-5">{newsForm?.name}</h3>
                                    {/**end::Title**/}
                                    {/**begin::Text**/}
                                    {/* <div className="fs-5 text-muted fw-semibold">{newsForm?.name}</div> */}
                                    {/**end::Text**/}
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: newsForm.des }} />
                            </div>
                            {/**end::Description**/}
                        </div>
                    </div>
                    {/**end::Body**/}
                </div>
            </div>
        </div>
    );
};

export default Detail;
