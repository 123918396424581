import React, { useEffect, useState } from 'react';
import Pagination from '../../../common/Pagination/pagination';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { useDispatch } from "react-redux";
import appSettings from '../../../configs/AppConfig';
import { sendRequest } from '../../../apis/APIs';
import { handleApiResponse } from '../../../apis/apiUtils';
import { toast } from 'react-toastify';
import { validateFormData } from './Validation';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import appRoutes from '../../../configs/AppRoutes';
import { Helmet } from 'react-helmet';

const List = () => {
    const [carrierList, setCarrierList] = useState([]);
    const [paginationData, setPaginationData] = useState({ perpage: appSettings.recordsPerPageTable, page: 1, totalCount: 0 });
    const [filter, setFilter] = useState({ search_string: '' });
    const [apiKeyData, setApiKeyData] = useState({ authorization_id: '', ip_address: '' });
    const dispatch = useDispatch();
    const [errorsInfo, setErrorsInfo] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        getApiKeyData();
    }, []);

    useEffect(() => {
        if(filter.search_string.length > 3 || filter.search_string == ""){
            getCarrierList(1);
        }
    }, [filter.search_string]);

    const getCarrierList = async (page) => {
        try {
            dispatch(callCommonAction({ loading: true, breadCrumb: ['Carrier', 'List'] }));
            const res = await sendRequest('/carrier/list', 'GET', { page: page, limit: paginationData.perpage, search_string: filter.search_string });
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                setCarrierList(res.data.data.docs);
                setPaginationData({ ...paginationData, totalCount: res.data.data.totalDocs, page: page });
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    const getApiKeyData = async () => {
        try {
            dispatch(callCommonAction({ loading: true, breadCrumb: ['Carrier', 'List'] }));
            const res = await sendRequest('/carrier/api-key', 'GET');
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                setApiKeyData({ authorization_id: res.data.data.authorization_id, ip_address: res.data.data.ip_address });
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    const renderTableBody = () => {
        return carrierList.map((carrier) => (
            <tr key={Math.random()}>
                <td><img src={carrier.Logos.Large} width={80} /></td>
                <td>{carrier.label}</td>
                <td>{carrier.value}</td>
                <td className="text-end">
                        <Dropdown>
                            <Dropdown.Toggle className='btn btn-light btn-active-light-primary btn-sm' id="dropdown-basic">
                                Actions
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href={undefined} onClick={() => navigate(appRoutes.adminCarrierDetailRoute + '/' + carrier._id)}>View</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
            </tr>
        ));
    };

    /** Handle Input Change Manage Heere */
    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        apiKeyData[name] = value;
        setApiKeyData({ ...apiKeyData, [name]: value });
        checkFormIsValid(apiKeyData, name);
    };

    const checkFormIsValid = (data, fieldName) => {
        const res = validateFormData(data, fieldName);
        setErrorsInfo(res.errors);
        return res.formVaildCheck;
    };

    const handleApiFormSubmit = async (e) => {
        e.preventDefault();
        if (checkFormIsValid(apiKeyData, null)) {
            try {
                dispatch(callCommonAction({ loading: true }));
                const res = await sendRequest('/carrier/update-api-key/6583e61082e13b66ff4e6aa3', 'POST', apiKeyData);
                dispatch(callCommonAction({ loading: false }));
                handleApiResponse(res);
            } catch (error) {
                toast.error('Something went wrong');
                dispatch(callCommonAction({ loading: false }));
            }
        }
    };


    return (
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <Helmet>
                <title>iQuote | Carrier List </title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>
            {/**begin::Post**/}
            <div className="post d-flex flex-column-fluid" id="kt_post">
                {/**begin::Container**/}
                <div id="kt_content_container" className="container-xxl">
                    {/**begin::Card**/}
                    <div className="card">
                        {/**begin::Card header**/}
                        <div className="card-header">
                            {/**begin::Card title**/}
                            <div className="card-title">
                                <h4>Api Key Management</h4>
                            </div>
                        </div>
                        {/**end::Card header**/}
                        {/**begin::Card body**/}
                        <div className="card-body">
                            <form onSubmit={handleApiFormSubmit}>
                                <div className="row row-cols-1 row-cols-sm-3">
                                    <div className="col">
                                        <div className="fv-row mb-7 fv-plugins-icon-container">
                                            <label className="fs-6 fw-semibold form-label mt-3">
                                                <span className="required">Authorization ID</span>
                                            </label>
                                            <input type="text" onChange={handleChangeInput} value={apiKeyData.authorization_id} className={`form-control form-control-solid ${errorsInfo.authorization_id ? 'is-invalid' : ''}`} name="authorization_id" />
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    <span role="alert">{errorsInfo.authorization_id}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="fv-row mb-7 fv-plugins-icon-container">
                                            <label className="fs-6 fw-semibold form-label mt-3">
                                                <span className="required">IP Address</span>
                                            </label>
                                            <input type="text" onChange={handleChangeInput} value={apiKeyData.ip_address} className={`form-control form-control-solid ${errorsInfo.ip_address ? 'is-invalid' : ''}`} name="ip_address" />
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    <span role="alert">{errorsInfo.ip_address}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col align-items-start d-flex flex-column">
                                        <label className="fs-6 fw-semibold form-label mt-3">
                                            &nbsp;
                                        </label>
                                        <button type="submit" className='btn btn-primary'>Save</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/**end::Card body**/}
                    </div>
                    {/**end::Card**/}
                </div>
            </div>
            {/**begin::Post**/}
            <div className="post d-flex flex-column-fluid pt-4" id="kt_post">
                {/**begin::Container**/}
                <div id="kt_content_container" className="container-xxl">
                    <h4 className='mb-3 ps-5'>Carrier List</h4>
                    {/**begin::Card**/}
                    <div className="card">
                        {/**begin::Card header**/}
                        <div className="card-header">
                            {/**begin::Card title**/}
                            <div className="card-title">
                                {/**begin::Search**/}
                                <div className="d-flex align-items-center position-relative my-1">
                                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                    <input type="text" value={filter.search_string} onChange={(e) => setFilter({ ...filter, search_string: e.target.value })} className="form-control form-control-solid w-250px ps-13 border" placeholder="Search carrier" />
                                </div>
                                {/**end::Search**/}
                            </div>
                        </div>
                        {/**end::Card header**/}
                        {/**begin::Card body**/}
                        <div className="card-body py-0 table-frame">
                            {/**begin::Table wrapper**/}
                            <div className="table-responsive">
                                {/**begin::Table**/}
                                <table className="table align-middle table-row-dashed fw-semibold text-gray-600 fs-6 gy-5" id="kt_table_customers_logs">
                                    {/**begin::Table body**/}
                                    <thead>
                                        <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                            <th className="min-w-125px">Image</th>
                                            <th className="min-w-125px">Name</th>
                                            <th className="min-w-125px">Compinc</th>
                                            <th className="min-w-125px text-end">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {carrierList.length > 0 ? renderTableBody() : <tr>
                                            <td colSpan={4} className='text-center'>No result found!</td>
                                        </tr>}
                                    </tbody>
                                    {/**end::Table body**/}
                                </table>
                                {/**end::Table**/}
                                
                            </div>
                            <div className='pag mb-4'>
                                    <Pagination className="pagination-bar" currentPage={paginationData.page} totalCount={paginationData.totalCount}
                                        pageSize={paginationData.perpage} onPageChange={page => getCarrierList(page)}
                                    />
                                </div>
                            {/**end::Table wrapper**/}
                        </div>
                        {/**end::Card body**/}
                    </div>
                    {/**end::Card**/}
                </div>
            </div>
        </div>
    );
};

export default List;