export function validateFormData(data, fieldName) {
    const { authorization_id, ip_address } = data;
    let errorMsg = ''; let errors = {}; let formVaildCheck = true;
    if (authorization_id === '' || fieldName === '') {
        errorMsg = authorization_id === '' ? 'This field is required' : '';
        errors.authorization_id = errorMsg;
        formVaildCheck = false;
    }
    if (ip_address === '' || fieldName === '') {
        errorMsg = ip_address === '' ? 'This field is required' : '';
        errors.ip_address = errorMsg;
        formVaildCheck = false;
    }

    return { formVaildCheck: formVaildCheck, errors: errors };
}