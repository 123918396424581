import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { useDispatch, useSelector } from 'react-redux';
import { sendRequest } from '../../../apis/APIs';
import { handleApiResponse } from '../../../apis/apiUtils';
import { toast } from 'react-toastify';
import appRoutes from '../../../configs/AppRoutes';
import BackButton from '../../../common/BackButton';
import Dropdown from 'react-bootstrap/Dropdown';
import moment from 'moment';
import appSettings from '../../../configs/AppConfig';
import Pagination from '../../../common/Pagination/pagination';
import { Helmet } from 'react-helmet';


const Detail = () => {
    const { isLoggedIn } = useSelector((state) => state.common);
    const params = useParams();
    const dispatch = useDispatch();
    const [policyDetail, setPolicyDetail] = useState({});
    //const [paginationData, setPaginationData] = useState({ perpage: appSettings.recordsPerPageTable, page: 1, totalCount: 0 });
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            getPolicy();
        }
    }, []);
    const getPolicy = async () => {
        try {
            dispatch(callCommonAction({ loading: true, breadCrumb: ['Policy', 'Detail', appRoutes.adminPolicyListRoute] }));
            const res = await sendRequest('/policy/question', 'GET', { policy: params.id, all_questions: false });
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                setPolicyDetail(res.data.data);
                //setPaginationData({ ...paginationData, totalCount: res.data.data?.users?.totalDocs, page: res.data.data?.users?.page });
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    const renderTabeBody = () => {
        return policyDetail?.policyQuestion?.questions.length > 0 ? policyDetail?.policyQuestion?.questions.map((question) => {
            let badgeCls = question.status === 1 ? 'success' : 'danger';
            return (
                <tr key={question.id}>

                    <td>{question.name}</td>
                    <td className='text-center'>{question.type}</td>
                    <td className='text-end'><span className={`text-uppercase badge badge-light-${badgeCls}`}>{question.status === 1 ? 'Active' : 'Inactive'}</span></td>
                </tr>
            );
        }) : null;

    };


    return (
        <>
            <Helmet>
                <title>iQuote | Policy Deatil</title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div id="kt_content_container" className="container-xxl">
                    <BackButton />
                    {/** Detail page START **/}
                    <div className="card mb-0" id="kt_profile_details_view">
                        <div className="card-header align-items-center">
                            <div className="card-title m-0">
                                <h3 className="fw-bold m-0">{policyDetail?.policy?.name}</h3>
                            </div>
                            <div className={`text-uppercase ms-auto badge ${policyDetail?.policy?.status === 1 ? "badge-light-success" : "badge-light-danger"}`}>
                                <a>{policyDetail?.policy?.status_name}</a>
                            </div>
                        </div>
                    </div>
                    <div className="card mt-8">
                        {/**begin::Card header**/}
                        <div className="card-header">
                            {/**begin::Card title**/}
                            <div className='card-title m-0'>
                                <h3 className="fw-bold m-0">Question list</h3>
                            </div>
                            {/**begin::Card title**/}
                            {/**begin::Card toolbar**/}
                            {/**end::Card toolbar**/}
                        </div>
                        {/**end::Card header**/}
                        {/**begin::Card body**/}
                        <div className="card-body table-frame py-4">
                            {/**begin::Table**/}
                            <div className='table-responsive'>
                            <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users">
                                <thead>
                                    <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                        <th className="min-w-125px">Name</th>
                                        <th className="min-w-125px text-center">Type</th>
                                        <th className="min-w-125px text-end">Status</th>
                                    </tr>
                                </thead>
                                <tbody className="text-gray-600 fw-semibold">
                                    {policyDetail?.policyQuestion?.questions?.length > 0 ? renderTabeBody() : <tr>
                                        <td colSpan={3} className='text-center'>No result found!</td>
                                    </tr>}
                                </tbody>
                            </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Detail;
