import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { sendRequest } from "../../apis/APIs";
import { callCommonAction } from "../../redux/Common/CommonReducer";
import { useNavigate, useParams } from "react-router-dom";
import appRoutes from "../../configs/AppRoutes";
import { validateResetPasswordFormData } from "./Validation";
import 'react-toastify/dist/ReactToastify.css'; // import first
import { toast } from 'react-toastify'; // then this
import { handleApiResponse } from "../../apis/apiUtils";
import { Helmet } from "react-helmet";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState({ password: '', password_confirmation: '', token: '' });
  const isAdminUrl = window.location.href.includes('admin');
  const { loading } = useSelector((state) => state.common)
  const dispatch = useDispatch();
  const [errorsInfo, setErrorsInfo] = useState({});
  const { token } = useParams();

  useEffect(() => {
    setUserDetail({ ...userDetail, token: token });
  }, []);

  /** Reset Password Form OnChange */
  const handleChange = (e) => {
    userDetail[e.target.name] = e.target.value;
    setUserDetail(userDetail);
    checkFormIsValid(e.target.name)
  }
  /** Validation implemented here */
  const checkFormIsValid = (fieldName) => {
    const res = validateResetPasswordFormData(userDetail, fieldName);
    setErrorsInfo(res.errors);
    return res.formVaildCheck;
  }

  /** Reset Password Form Submit */
  const resetPassword = async (e) => {
    e.preventDefault();
    if (checkFormIsValid('')) {
      try {
        dispatch(callCommonAction({ loading: true }));
        let reqUrl = isAdminUrl ? '/admin/reset-password' : `/user/reset-password`;
        const res = await sendRequest(reqUrl, 'POST', userDetail);
        dispatch(callCommonAction({ loading: false }));
        handleApiResponse(res);
        if (res.data.type === "success") {
          setUserDetail({ password: '', password_confirmation: ''});
          navigate(isAdminUrl ? appRoutes.adminLoginRoute : appRoutes.loginRoute);
        }
      } catch (error) {
        toast.error('Something went wrong');
        dispatch(loading({ loading: false }));
      }
    }
  }

  return (
    <div>
       <Helmet>
        <title>iQuote | Reset Password</title>
        <meta name="description" content="Description goes here" />
        <meta name="keywords" content="Game, Entertainment, Movies" />
      </Helmet>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        id="kt_login_signup_form"
        noValidate onSubmit={resetPassword}
      >
        <div className="text-center mb-11">
          <h1 className="text-gray-900 fw-bolder mb-3">Reset Password</h1>
        </div>

          <div className="fv-row mb-8" data-kt-password-meter="true">
            <div className="mb-1">
              <label className="form-label fw-bolder text-gray-900 fs-6">
                Password
              </label>
              <div className="position-relative mb-3">
                <input
                  type="password"
                  placeholder="Password"
                  autoComplete="off"
                  name="password"
                  className={`form-control bg-transparent ${errorsInfo.password ? 'is-invalid' : ''}`}
                  onChange={handleChange}
                  value={userDetail.password}
                />
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{errorsInfo.password}</span>
                  </div>
                </div>
              </div>

            </div>
            <div className="text-muted">
              Use 8 or more characters with a mix of letters, numbers &amp;
              symbols.
            </div>
          </div>
          <div className="fv-row mb-5">
            <label className="form-label fw-bolder text-gray-900 fs-6">
              Confirm Password
            </label>
            <input
              type="password"
              placeholder="Password confirmation"
              autoComplete="off"
              name="password_confirmation"
              className={`form-control bg-transparent ${errorsInfo.password_confirmation ? 'is-invalid' : ''}`}
              onChange={handleChange}
              value={userDetail.password_confirmation}
            />
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errorsInfo.password_confirmation}</span>
              </div>
            </div>
          </div>

        <div className="text-center">
          <button
            type="submit"
            id="kt_sign_up_submit"
            className="btn btn-lg btn-primary w-100 mb-5"
            disabled={loading ? true : false}
          >
            {
              loading
                ?
                <span className="indicator-progress" style={{ display: 'block' }}>
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
                :
                <span className="indicator-label">Submit</span>
            }

          </button>
          <button
            type="button"
            id="kt_login_signup_form_cancel_button"
            className="btn btn-lg btn-light-primary w-100 mb-5"
            onClick={() => navigate(navigate(isAdminUrl ? appRoutes.adminLoginRoute : appRoutes.loginRoute))}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
