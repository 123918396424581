import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";
import Input from 'react-phone-number-input/input';
import { sendRequest } from "../../apis/APIs";
import { callCommonAction } from "../../redux/Common/CommonReducer";
import { useNavigate } from "react-router-dom";
import appRoutes from "../../configs/AppRoutes";
import { validateRegisterFormData } from "./Validation";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import 'react-toastify/dist/ReactToastify.css'; // import first
import { toast } from 'react-toastify'; // then this
import { handleApiResponse } from "../../apis/apiUtils";
import { Helmet } from "react-helmet";


const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const Register = () => {
  const checkoutFormRef = useRef();
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState({
    terms: false,
    phonenumber: "",
    role: 2,
    first_name: "",
    display_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    price_id: process.env.REACT_APP_PRICE_ID,
  });
  const [errorsInfo, setErrorsInfo] = useState({
    terms: false,
    phonenumber: "",
    first_name: "",
    display_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });
  const { loading } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  /** OnChange Update Input Values */
  const handleChange = (e) => {
    userDetail[e.target.name] = e.target.value;
    setUserDetail(userDetail);
    checkFormIsValid(e.target.name);
  };
  const handleTermsChange = (e) => {
    userDetail["terms"] = e.target.checked;
    setUserDetail(userDetail);
    checkFormIsValid("");
  };

  /** handlePhoneChange */
  const handlePhoneChange = (phonenumber) => {
    if (phonenumber) {
      console.log('phone', phonenumber);
      userDetail.phonenumber = phonenumber;
      setUserDetail(userDetail);
      checkFormIsValid("phonenumber");
    }
  };


  /** Validation implemented here */
  const checkFormIsValid = async (fieldName) => {
    const res = validateRegisterFormData(userDetail, fieldName, isValidPhoneNumber);
    if (fieldName) {
      fieldName = fieldName.trim();
      let err = res.errors[fieldName];
      if (typeof err != "undefined") {
        errorsInfo[fieldName] = err;
      } else {
        errorsInfo[fieldName] = "";
      }
      setErrorsInfo({ ...errorsInfo });
    } else {
      setErrorsInfo({ ...res.errors });
    }
    return res.formVaildCheck;
  };

  /** Form Submit Functionality Here */
  const registerUser = async (e) => {
    e.preventDefault();
    if (await checkFormIsValid("")) {
      try {
        dispatch(callCommonAction({ loading: true }));

        let paymentMethod = await checkoutFormRef.current.handleCardElement();  //Call stripe Api

        if (paymentMethod && paymentMethod.id) {

          let body = { ...userDetail };
          body.payment_method_id = paymentMethod.id;

          const res = await sendRequest(`/user/register`, "POST", body);
          dispatch(callCommonAction({ loading: false }));
          handleApiResponse(res);
          if (res.data.type === "success") {
            setUserDetail({ terms: false, phonenumber: "", role: 2, first_name: "", display_name: "", last_name: "", email: "", password: "", password_confirmation: "", price_id: process.env.REACT_APP_PRICE_ID });
            navigate(appRoutes.loginRoute);
          }
        } else {
          dispatch(callCommonAction({ loading: false }));
        }
      } catch (error) {
        toast.error('Something went wrong');
        dispatch(callCommonAction({ loading: false }));
      }
    }
  };

  return (
    <div>
      <Helmet>
        <title>iQuote | Register Form</title>
        <meta name="description" content="Description goes here" />
        <meta name="keywords" content="Game, Entertainment, Movies" />
      </Helmet>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        id="kt_login_signup_form"
        noValidate onSubmit={registerUser}
      >
        <div className="text-center mb-11">
          <h1 className="text-gray-900 fw-bolder mb-3">Sign Up For Agent</h1>
        </div>
        <div className='fv-row mb-8'>
          <div className="active btn btn-active btn-active-primary btn-color-dark btn-outline btn-outline-dashed d-flex flex-stack mb-6 nav-link px-4 py-2 text-start" data-bs-toggle="tab" data-bs-target="#kt_upgrade_plan_startup">
            <div className="d-flex align-items-center me-2">
              <div className="form-check form-check-custom form-check-solid form-check-success me-6">
                <input className="form-check-input" type="radio" name="plan" defaultChecked value="startup" />
              </div>
              <div className="flex-grow-1">
                <h2 className="align-items-center d-flex flex-wrap fs-3 fw-bolder mb-0 text-white">Startup</h2>
                <div className="fw-bold opacity-50">Best for startups</div>
              </div>
            </div>
            <div className="ms-5">
              <span className="mb-2">$</span>
              <span className="fs-3x fw-bolder">37</span>
              <span className="fs-7 opacity-50">/
                <span data-kt-element="period">Mon</span></span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="fv-row mb-8 col">
            <label className="form-label fw-bolder text-gray-900 fs-6">
              First Name
            </label>
            <input
              placeholder="Enter first name"
              type="text"
              autoComplete="off"
              name="first_name"
              className={`form-control bg-transparent ${errorsInfo.first_name ? 'is-invalid' : ''}`}
              onChange={handleChange}
              value={userDetail.first_name}
            />
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errorsInfo.first_name}</span>
              </div>
            </div>
          </div>

          <div className="fv-row mb-8 col">
            <label className="form-label fw-bolder text-gray-900 fs-6">
              Last Name
            </label>
            <input
              placeholder="Enter last name"
              type="text"
              autoComplete="off"
              name="last_name"
              className={`form-control bg-transparent ${errorsInfo.last_name ? 'is-invalid' : ''}`}
              onChange={handleChange}
              value={userDetail.last_name}
            />
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errorsInfo.last_name}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="fv-row mb-8 col">
            <label className="form-label fw-bolder text-gray-900 fs-6">
              Display Name
            </label>
            <input
              placeholder="Enter display name"
              type="text"
              autoComplete="off"
              name="display_name"
              className={`form-control bg-transparent ${errorsInfo.display_name ? 'is-invalid' : ''}`}
              onChange={handleChange}
              value={userDetail.display_name}
            />
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errorsInfo.display_name}</span>
              </div>
            </div>
          </div>
          <div className="fv-row mb-8 col">
            <label className="form-label fw-bolder text-gray-900 fs-6">
              Phone Number
            </label>
            <Input
              country="US"
              placeholder="Phone Number"
              className={`form-control bg-transparent ${errorsInfo.phonenumber ? 'is-invalid' : ''}`}
              onChange={(e) => handlePhoneChange(e)}
              value={userDetail.phonenumber}
            />
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errorsInfo.phonenumber}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="fv-row mb-8 ">
          <label className="form-label fw-bolder text-gray-900 fs-6">Email</label>
          <input
            placeholder="Email"
            type="email"
            autoComplete="off"
            name="email"
            className={`form-control bg-transparent ${errorsInfo.email ? 'is-invalid' : ''}`}
            onChange={handleChange}
            value={userDetail.email}
          />
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{errorsInfo.email}</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="fv-row mb-8 col" data-kt-password-meter="true">
            <div className="mb-1">
              <label className="form-label fw-bolder text-gray-900 fs-6">
                Password
              </label>
              <div className="position-relative mb-3">
                <input
                  type="password"
                  placeholder="Password"
                  autoComplete="off"
                  name="password"
                  className={`form-control bg-transparent ${errorsInfo.password ? 'is-invalid' : ''}`}
                  onChange={handleChange}
                  value={userDetail.password}
                />
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{errorsInfo.password}</span>
                  </div>
                </div>
              </div>

            </div>
            {/* <div className="text-muted">
            Use 8 or more characters with a mix of letters, numbers &amp;
            symbols.
          </div> */}
          </div>
          <div className="fv-row mb-5 col">
            <label className="form-label fw-bolder text-gray-900 fs-6">
              Confirm Password
            </label>
            <input
              type="password"
              placeholder="Confirm Password"
              autoComplete="off"
              name="password_confirmation"
              className={`form-control bg-transparent ${errorsInfo.password_confirmation ? 'is-invalid' : ''}`}
              onChange={handleChange}
              value={userDetail.password_confirmation}
            />
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errorsInfo.password_confirmation}</span>
              </div>
            </div>
          </div>
        </div>

        <div className='fv-row mb-5'>
          <Elements stripe={stripePromise}>
            <CheckoutForm ref={checkoutFormRef} />
          </Elements>
        </div>
        <div className="fv-row mb-8 mt-8">
          <label className="form-check form-check-inline" >
            <input
              className={`form-check-input ${errorsInfo.terms ? 'is-invalid' : ''}`}
              type="checkbox"
              name="terms"
              value="false"
              onChange={handleTermsChange}
            />
            <span>
              I Accept the{" "}
              <a
                href={undefined}
                target="_blank"
                className="ms-1 link-primary cursor-pointer"
              >
                Terms
              </a>
              .
            </span>
          </label>
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{errorsInfo.terms}</span>
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            type="submit"
            id="kt_sign_up_submit"
            className="btn btn-lg btn-primary w-100 mb-5"
            disabled={loading ? true : false}
          >
            {
              loading
                ?
                <span className="indicator-progress" style={{ display: 'block' }}>
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
                :
                <span className="indicator-label">Submit</span>
            }

          </button>
          <button
            type="button"
            id="kt_login_signup_form_cancel_button"
            className="btn btn-lg btn-light-primary w-100 mb-5"
            onClick={() => navigate(appRoutes.loginRoute)}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default Register;
