import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css'; // import first
import { toast } from 'react-toastify'; // then this
import { validateOfferFormData } from './Validation';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { sendRequest } from '../../../apis/APIs';
import { handleApiResponse } from '../../../apis/apiUtils';
import { useDispatch, useSelector } from 'react-redux';
import appRoutes from '../../../configs/AppRoutes';
import BackButton from '../../../common/BackButton';
import { Helmet } from 'react-helmet';

const AddEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [offerForm, setOfferForm] = useState({ name: "" });
  const [errorsInfo, setErrorsInfo] = useState({ name: "" });
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);

  useEffect(() => {
    if (params.id !== undefined) getAddEditFormData();
    dispatch(callCommonAction({ breadCrumb: ['Offer', params.id === undefined ? 'Add Form' : 'Edit Form', appRoutes.adminOfferListRoute] }));
  }, [""]);


  const getAddEditFormData = async () => {
    try {
      dispatch(callCommonAction({ loading: true }));
      const res = await sendRequest('/offer/form/' + params.id, 'GET');
      dispatch(callCommonAction({ loading: false }));
      handleApiResponse(res);
      if (res.data.type === "success") {
        setOfferForm(res.data.data);
      }
    } catch (error) {
      toast.error('Something went wrong');
      dispatch(callCommonAction({ loading: false }));
    }
  };

  /** Handle Change ****/
  const handleChange = (e) => {
    offerForm[e.target.name] = e.target.value;
    setOfferForm({ ...offerForm, [e.target.name]: e.target.value });
    checkFormIsValid(e.target.name);
  };

  /** Validation implemented here */
  const checkFormIsValid = async (fieldName) => {
    const res = validateOfferFormData(offerForm, fieldName);
    setErrorsInfo(res.errors);
    return res.formVaildCheck;
  };

  /** Submit Form Data */
  const formSubmit = async (e) => {
    e.preventDefault();
    if (await checkFormIsValid('')) {
      try {
        dispatch(callCommonAction({ loading: true }));
        var url = params.id === undefined ? '/offer/create' : '/offer/update/' + params.id;
        const res = await sendRequest(url, 'POST', offerForm);
        dispatch(callCommonAction({ loading: false }));
        handleApiResponse(res);
        if (res.data.type === "success") {
          navigate(appRoutes.adminOfferListRoute);
        }
      } catch (error) {
        toast.error('Something went wrong');
        dispatch(callCommonAction({ loading: false }));
      }
    }
  };


  return (
    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
      <Helmet>
        <title>iQuote | Offer Form</title>
        <meta name="description" content="Description goes here" />
        <meta name="keywords" content="Game, Entertainment, Movies" />
      </Helmet>
      <div id="kt_content_container" className="container-xxl">
        <BackButton />
        <div className="card">
          <div className="card-header" id="kt_chat_contacts_header">
            <div className="card-title">
              <i className="ki-duotone ki-badge fs-1 me-2">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
                <span className="path5"></span>
              </i>
              <h2>Offer Form</h2>
            </div>
          </div>
          <div className="card-body table-frame pt-5">
            <form className="form fv-plugins-bootstrap5 fv-plugins-framework" noValidate onSubmit={formSubmit}>
              <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                <div className="col">
                  <div className="fv-row fv-plugins-icon-container">
                    <label className="fs-6 fw-semibold form-label mt-3">
                      <span className="required">Name</span>
                    </label>
                    <input type="text" onChange={handleChange} value={offerForm.name} className={`form-control form-control-solid ${errorsInfo.name ? 'is-invalid' : ''}`} name="name" />
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errorsInfo.name}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col'>
                  <div className='d-flex flex-column align-items-start'>
                    <label className="fs-6 fw-semibold form-label mt-3">&nbsp;</label>
                    <button type="submit" id="kt_sign_up_submit"
                      className="btn btn-primary me-2" disabled={loading ? true : false}>
                      {
                        loading
                          ?
                          <span className="indicator-progress" style={{ display: 'block' }}>
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                          :
                          <span className="indicator-label">Save</span>
                      }
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>

  );
};
export default AddEdit;
