import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { sendRequest } from '../../../apis/APIs';
import { handleApiResponse } from '../../../apis/apiUtils';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import appRoutes from '../../../configs/AppRoutes';
import { Helmet } from 'react-helmet';

const Detail = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [leadData, setLeadData] = useState({});

    useEffect(() => {
        getLeadDetail();
    }, []);

    const getLeadDetail = async () => {
        try {
            dispatch(callCommonAction({ loading: true, breadCrumb: ['Lead', 'Detail', appRoutes.leadListRoute] }));
            const res = await sendRequest('/lead/detail/' + params.id, 'GET');
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                setLeadData(res.data.data);
            }
        } catch (error) {
            //console.log('dfsd', error.message);
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    return (
        <div>
            <Helmet>
                <title>iQuote | Lead Detail</title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content"><div id="kt_content_container" className="container-xxl">
                <div className="card mb-6 mb-xl-9">
                    {/**begin::Card header**/}
                    <div className="card-header">
                        {/**begin::Card title**/}
                        <div className="card-title flex-column">
                            <h2 className="mb-1">Leads Info</h2>
                        </div>
                        {/**end::Card title**/}
                        {/**begin::Card toolbar**/}
                        <div className="card-toolbar">
                            <span className="btn btn-primary btn-sm" onClick={() => navigate(-1)}><i className="ki-duotone ki-left"></i>Back</span>
                        </div>
                        {/**end::Card toolbar**/}
                    </div>
                    {/**end::Card header**/}
                    {/**begin::Card body**/}
                    <div className="card-body table-frame">
                        <div className='border p-5 rounded mb-5 shadow-sm'>
                            <div className="border-bottom fs-3 fw-semibold mb-5 pb-2">General Information</div>
                            <div className="row">
                                <div className="fs-6 fw-semibold mb-5 text-muted col-12 col-md-6 col-xl-3"><span className="text-body">First Name:</span> {leadData?.firstName}</div>
                                <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3"><span className="text-body">Last Name:</span> {leadData?.lastName}</div>
                                <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3"><span className="text-body">Email:</span> {leadData?.email}</div>
                                <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3"><span className="text-body">Phone:</span> {leadData?.phone}</div>
                            </div>
                            <div className="row">
                                <div className="fs-6 fw-semibold mb-5 text-muted col-12 col-md-6 col-xl-3"><span className="text-body">Date of Birth:</span> {moment(leadData.dateOfBirth).format('MM/DD/YYYY')} </div>
                                <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3"><span className="text-body">Postal Code:</span> {leadData?.postalCode}</div>
                                <div className="fs-6 fw-semibold mb-5 text-muted col-12 col-md-6 col-xl-3"><span className="text-body">Lowest Price:</span> {leadData?.lowestPrice}</div>
                                {/* <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3"><span className="text-body">Address:</span> {leadData?.address1}</div> */}
                                <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3"><span className="text-body">Created Date:</span> {moment(leadData.created_at).format('MM/DD/YYYY')}</div>
                            </div>
                            <div className="row">
                                <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3"><span className="text-body">Highest Price:</span> {leadData?.heighestPrice}</div>
                                {leadData._id !== undefined && leadData.state != undefined && leadData.state != 'null' ? <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3"><span className="text-body">State:</span> {leadData?.state?.label}</div> : ''}
                                {leadData._id !== undefined && leadData.health != undefined && leadData.health != 'null' ? <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3"><span className="text-body">Health:</span> {leadData?.health?.label}</div> : ''}
                                {leadData._id !== undefined && leadData.category != undefined && leadData.category != 'null' ? <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3"><span className="text-body">Category:</span> {leadData?.category?.label}</div> : ''}
                            </div>
                        </div>

                        {
                            leadData.id !== undefined && leadData.leadQuestions.length
                                ?
                                <div className='border p-5 rounded mb-5 shadow-sm'>
                                    <div className="border-bottom fs-3 fw-semibold mb-5 pb-2">Lead Note</div>
                                    <div className="row">
                                        {leadData.leadQuestions.map((leadQues, index) => (
                                            <React.Fragment key={leadQues._id}>
                                                <div className="fs-6 fw-semibold mb-5 text-muted col-12 col-md-6 col-xl-3">
                                                    <span className="text-body">{leadQues.question.name}:</span> {
                                                        leadQues.value_type == 'select' || leadQues.value_type == 'radio'
                                                            ?
                                                            leadQues.question.question_options.map((option) => {
                                                                return leadQues.question_options.includes(option._id) ? option.label : null;
                                                            }).filter((el) => el != null).join(',')
                                                            :
                                                            leadQues.value
                                                    }
                                                </div>

                                            </React.Fragment >
                                        ))}
                                    </div>
                                </div>
                                :
                                null
                        }

                        {
                            leadData.id !== undefined && leadData.offers.length > 0
                                ?
                                <div className='border p-5 rounded mb-5 shadow-sm'>
                                    <div className="border-bottom fs-3 fw-semibold mb-5 pb-2">Offers</div>
                                    <div className="d-flex flex-wrap">
                                        {
                                            leadData.offers.map((offer) => {
                                                return (
                                                    <div className="border border-dashed border-gray-300 mb-3 me-3 px-3 py-3 rounded">
                                                        <div className="fw-bold text-gray-700">{offer.name}</div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                                :
                                null
                        }

                        {
                            leadData.id !== undefined && leadData.carriers.length > 0
                                ?
                                <div className='border p-5 rounded mb-5 shadow-sm'>
                                    <div className="border-bottom fs-3 fw-semibold mb-5 pb-2">Carriers</div>
                                    <div className="d-flex flex-wrap">
                                        {
                                            leadData.carriers.map((carrier) => {
                                                return (
                                                    <div className="border border-dashed border-gray-300 mb-3 me-3 px-3 py-3 rounded">
                                                        <div className="fw-bold text-gray-700">{carrier.label}</div>
                                                    </div>
                                                );
                                            })
                                        }

                                    </div>
                                </div>
                                :
                                null
                        }
                        
                        {
                            leadData.id !== undefined && leadData.policies.length > 0
                                ?
                                <div className='border p-5 rounded mb-5 shadow-sm'>
                                    <div className="border-bottom fs-3 fw-semibold mb-5 pb-2">Policies</div>
                                    <div className="d-flex flex-wrap">
                                        {
                                            leadData.policies.map((policy) => {
                                                return (
                                                    <div className="border border-dashed border-gray-300 mb-3 me-3 px-3 py-3 rounded">
                                                        <div className="fw-bold text-gray-700">{policy.name}</div>
                                                    </div>
                                                );
                                            })
                                        }

                                    </div>
                                </div>
                                :
                                null
                        }


                    </div>
                    {/**end::Card body**/}
                </div>
            </div>
            </div>
        </div>
    );
};

export default Detail;
