import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css'; // import first
import { toast } from 'react-toastify'; // then this
import { validateQuestionFormData } from './Validation';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { sendRequest } from '../../../apis/APIs';
import { handleApiResponse } from '../../../apis/apiUtils';
import { useDispatch, useSelector } from 'react-redux';
import appRoutes from '../../../configs/AppRoutes';
import BackButton from '../../../common/BackButton';
import { Helmet } from 'react-helmet';

const AddEdit = () => {
	// Get the userId param from the URL.
	const params = useParams();
	const navigate = useNavigate();
	const { loading } = useSelector((state) => state.common);
	const [errorsInfo, setErrorsInfo] = useState({});
	const [questionForm, setQuestionForm] = useState({
		type: "text",
		type_option: [],
		is_visible: false,
		is_required: false,
		sort: 0,
		name: "",
	});
	const dispatch = useDispatch();

	useEffect(() => {
		if (params.id !== undefined) getAddEditFormData();
		dispatch(callCommonAction({ breadCrumb: ['Question', 'Form', appRoutes.adminQuestionListRoute] }));
	}, []);

	/******PREPARE TYPE OPTIONS******/
	const prepareTypeOptions = (value) => {
		let optionRawData = [];
		if ((value == 'checkbox' || value == 'radio' || value == 'select')) {
			optionRawData = [{ name: value, value: '', label: '', error_name_option: '', error_name_text: '' }];
		} else {
			optionRawData = questionForm.type_option;
		}
		setQuestionForm((prevState) => ({ ...prevState, type_option: optionRawData }));
	};

	/******HANDLE CHANGE COVER******/
	const handleChange = (e) => {
		const { name, value } = e.target;
		console.log(`name ==>`, name, 'value ==>', value,);
		setQuestionForm({ ...questionForm, [name]: value });
		if (name == 'type' && (value == 'checkbox' || value == 'radio' || value == 'select')) {
			prepareTypeOptions(value);
		} else if (name == 'type' && (value != 'checkbox' && value != 'radio' && value != 'select')) {
			// setQuestionForm((prevState) => ({ ...prevState, type_option: [] }));
		}
		checkFormIsValid(e.target.name);

	};

	/* Validation implemented here */
	const checkFormIsValid = (fieldName) => {
		const res = validateQuestionFormData(questionForm, fieldName);
		setErrorsInfo(res.errors);
		return res.formVaildCheck;
	};

	/******GET ADD EDIT FORM DATA******/
	const getAddEditFormData = async () => {
		try {
			dispatch(callCommonAction({ loading: true }));
			const res = await sendRequest('/question/form/' + params.id, 'GET');
			console.log('question edit time', res.data.data);
			dispatch(callCommonAction({ loading: false }));
			handleApiResponse(res);
			if (res.data.type === "success") {
				setQuestionForm({
					...questionForm,
					type: res.data.data.type,
					type_option: res.data.data.question_options ? res.data.data.type == 'number' || res.data.data.type == 'text' ? [] : res.data.data.question_options : [],
					is_visible: res.data.data.is_visible,
					is_required: res.data.data.is_required,
					sort: res.data.data.sort,
					name: res.data.data.name,
					description: res.data.data.description
				});
			}
		} catch (error) {
			toast.error('Something went wrong');
			dispatch(callCommonAction({ loading: false }));
		}
	};

	const incrAndDecrOptn = (actionType, actionName, removeIndex) => {
		if (actionType == 'plus') {
			const optionRawData = {
				name: actionName, value: '', label: '', error_name_option: '', error_name_text: ''
			};
			setQuestionForm({ ...questionForm, type_option: [...questionForm.type_option, optionRawData] });
		} else {
			const optionRawData = questionForm.type_option.filter((item, index) => index != removeIndex);
			setQuestionForm({ ...questionForm, type_option: optionRawData });
		}
	};

	const handleTypeOption = (e, index) => {
		const rawdata = [...questionForm.type_option];
		rawdata[index].value = e.target.value;
		rawdata[index].error_name_option = ((e.target.value == '') ? rawdata[index].error_name_option = `This field is required.` : '');
		setQuestionForm({ ...questionForm, type_option: rawdata });
		validateTypeOption();
	};

	const handleTypeOptionLabel = (e, index) => {
		const rawdata = [...questionForm.type_option];
		rawdata[index].label = e.target.value;
		rawdata[index].error_name_text = ((e.target.value == '') ? rawdata[index].error_name_text = `This field is required.` : '');
		setQuestionForm({ ...questionForm, type_option: rawdata });
		validateTypeOption();
	};

	const validateTypeOption = () => {
		let newEdit = [...questionForm.type_option]; let validateData = true;
		const newArrayTypOptn = newEdit.map((item) => {
			item.error_name_option = '';
			item.error_name_text = '';
			if (item.label === "") {
				item.error_name_text = `This field is required.`;
				validateData = false;
			}
			if (item.value === "") {
				item.error_name_option = `This field is required.`;
				validateData = false;
			}
			return item;
		});
		setQuestionForm({ ...questionForm, type_option: newArrayTypOptn });
		return validateData;
	};

	const submitForm = async (e) => {
		e.preventDefault();
		let checkDynInpValid = validateTypeOption();
		if (checkFormIsValid('') && checkDynInpValid) {
			try {
				//dispatch(callCommonAction({ loading: true }));
				var url = params.id === undefined ? '/question/create' : '/question/update/' + params.id;
				console.log('question edit time', questionForm);
				const res = await sendRequest(url, 'POST', questionForm);
				dispatch(callCommonAction({ loading: false }));
				handleApiResponse(res);
				if (res.data.type === "success") {
					navigate(appRoutes.adminQuestionListRoute);
				}
			} catch (error) {
				toast.error('Something went wrong');
				dispatch(callCommonAction({ loading: false }));
			}
		}
	};

	return (
		<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
			{console.log('questionForm',questionForm)}
			<Helmet>
				<title>iQuote | Question Form</title>
				<meta name="description" content="Description goes here" />
				<meta name="keywords" content="Game, Entertainment, Movies" />
			</Helmet>
			<div id="kt_content_container" className="container-xxl">
				<BackButton />
				<div className="card">
					<div className="card-header" id="kt_chat_contacts_header">
						<div className="card-title">
							<i className="ki-duotone ki-badge fs-1 me-2">
								<span className="path1"></span>
								<span className="path2"></span>
								<span className="path3"></span>
								<span className="path4"></span>
								<span className="path5"></span>
							</i>
							<h2>Question Form</h2>
						</div>
					</div>
					<div className='card-body table-frame'>
						<form className="add-atribute-sec" onSubmit={submitForm}>
							<div className='row align-items-start'>

								<div className='col-12 col-md-4 mb-3'>
									<label className='form-label' htmlFor="type">Type:</label>
									<select name="type" value={questionForm.type} className='form-select'
										onChange={handleChange}
									>
										<option value="">No option selected</option>
										<option value="select">Select</option>
										<option value="text">Text</option>
										<option value="radio">Radio</option>
										<option value="number">Number</option>
									</select>
									{
										(questionForm.type == 'checkbox' || questionForm.type == 'radio' || questionForm.type == 'select')
										?
										questionForm.type_option.map((item, index) => {
											return (
												<div className='row align-items-start' key={index}>
													<div className='col'>
														<label className='form-label'>Option Value</label>
														<input type='text' className='form-control' name="type" value={item.value || ''} onChange={(e) => handleTypeOption(e, index)} />
														<div className="fv-plugins-message-container">
															<div className="fv-help-block">
																<span role="alert">{item?.error_name_option}</span>
															</div>
														</div>
													</div>
													<div className='col'>

														<label className='form-label' htmlFor="sort">Option Text:</label>
														<input className='form-control'
															type="text"
															name="label"
															value={item.label || ''}
															onChange={(e) => handleTypeOptionLabel(e, index)}
														/>
														<div className="fv-plugins-message-container">
															<div className="fv-help-block">
																<span role="alert">{item?.error_name_text}</span>
															</div>
														</div>
													</div>
													<div className='col-md-2 align-self-start flex-wrap mt-8'>
													
														{questionForm.type_option.length - 1 == index
															?
															<button type="button" className='btn btn-primary mb-1' onClick={() => incrAndDecrOptn("plus", item.name, index)}>+</button>
															:
															<button type="button" className='btn btn-primary mb-1' onClick={() => incrAndDecrOptn("minus", item.name, index)}>-</button>
														}
													</div>

												</div>
											);
										})
										:
										null
									}
								</div>
								<div className='col-12 col-md-4 mb-3'>
									<label className='form-label' htmlFor="sort">Name:</label>
									<input className='form-control'
										type="text"
										name="name"
										value={questionForm.name}
										onChange={handleChange}
									/>
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">
											<span role="alert">{errorsInfo.name}</span>
										</div>
									</div>
								</div>
								<div className='col-12 col-md-4 mb-4 align-self-start'>
								<label className='form-label min-height-45' htmlFor=""></label>
									<button type="submit" id="kt_sign_up_submit"
										className="btn btn-primary me-2" disabled={loading ? true : false}
									>
										{
											loading
												?
												<span className="indicator-progress" style={{ display: 'block' }}>
													Please wait...
													<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
												</span>
												:
												<span className="indicator-label">Save</span>
										}

									</button>
								</div>
							</div>
							{/* <div className='row'>
								<div className='col-md-6'>

									<label className='form-label' htmlFor="sort">Sort:</label>
									<input className='form-control'
										type="number"
										name="sort"
										value={questionForm.sort}
										onChange={handleChange}
									/>
								</div>
							</div> */}

						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddEdit; 