import STRINGS from '../../../common/strings/strings';

export function validateQuestionFormData(data, fieldName) {
    const { name } = data;
    let errorMsg = ''; let errors = {}; let formVaildCheck = true;
    if (name === '' && (fieldName == 'name' || fieldName == '')) {
        errorMsg = name === '' ? STRINGS.nameReq : '';
        errors.name = errorMsg;
        formVaildCheck = false;
    }

    return { formVaildCheck: formVaildCheck, errors: errors };
}