import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendRequest } from '../../../apis/APIs';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import 'react-toastify/dist/ReactToastify.css'; // import first
import { toast } from 'react-toastify';
import { handleApiResponse } from '../../../apis/apiUtils';
import appSettings from '../../../configs/AppConfig';
import moment from 'moment';
import Pagination from '../../../common/Pagination/pagination';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import appRoutes from '../../../configs/AppRoutes';
import { CSVLink } from 'react-csv';
import { Helmet } from 'react-helmet';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const List = () => {
    const { isLoggedIn, loading1 } = useSelector((state) => state.common);
    const [leads, setLeads] = useState([]);
    const csvRef = useRef(null);
    const [csvLeads, setCsvLeads] = useState([]);
    const [carriers, setCarriers] = useState([]);
    const [policies, setPolicies] = useState([]);
    const [offers, setOffers] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [paginationData, setPaginationData] = useState({ perpage: appSettings.recordsPerPageTable, page: 1, totalCount: 0 });
    const [filter, setFilter] = useState({ offer: '', policy: '', carrier: '', search_string: '', status: 'all', sort_field_name: 'updated_at', sort_order: true, start_date: null, end_date: null });

    // State variable to control the visibility of the dropdown menu
    const [dropdownOpen, setDropdownOpen] = useState(false);

    // Function to handle the "Apply" button click
    const handleApplyClick = () => setDropdownOpen(false);

    /** Apply Filter */
    const applyFilter = () => {
        handleApplyClick();
        setPaginationData({ ...paginationData, page: 1 });
        getLeads(1, filter.status, filter.start_date, filter.end_date, filter.policy, filter.carrier, filter.offer);
    };

    /** Clear Filter */
    const clearFilter = async () => {
        handleApplyClick();
        setPaginationData({ ...paginationData, page: 1 });
        setFilter({ ...filter, start_date: null, end_date: null, status: 'all', policy: '', carrier: '', offer: '' });
        getLeads(1, 'all', null, null, '', '', '');
    };

    useEffect(() => {
        getLeadRelatedData();
    }, []);

    useEffect(() => {
        if (isLoggedIn && filter.search_string.length > 2 || filter.search_string === "") {
            getLeads(1, filter.status, filter.start_date, filter.end_date, filter.policy, filter.carrier, filter.offer);
        }
    }, [filter.search_string, filter.sort_field_name, filter.sort_order]);

    /** Export CSV **/
    const CsvExportButton = ({ data, filename, headers }) => {

        return (
            <>
                {
                    loading1
                        ?
                        <button type="button" className='btn btn-primary ms-2 flex-shrink-0 btn-sm'>
                            <span className="indicator-progress" style={{ display: 'block' }}>
                                Please wait...
                                <span className="spinner-border spinner-border-sm align-middle"></span>
                            </span>
                        </button>
                        :
                        <button onClick={async () => await getDataForCsv(1, filter.status, filter.start_date, filter.end_date)} type="button" className='btn btn-primary ms-2 flex-shrink-0 btn-sm'>
                            <i className="ki-duotone ki-exit-down fs-2">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </i>
                            Export
                        </button>

                }
                <CSVLink style={{ display: 'none' }} ref={csvRef} data={data} headers={headers} filename={filename} >
                    Export CSV
                </CSVLink>
            </>

        );
    };

    /** Get leads ***/
    const getDataForCsv = async (page, status, start_date, end_date) => {
        try {
            // No need for loading logic here

            dispatch(callCommonAction({ loading1: true }));
            let body = { page: page, limit: 100000, search_string: filter.search_string, status: status, sort_field_name: filter.sort_field_name, sort_order: filter.sort_order };
            if ((start_date !== null && start_date instanceof Date && !isNaN(start_date.getTime())) && (end_date !== null && end_date instanceof Date && !isNaN(end_date.getTime()))) {
                body.start_date = start_date.toISOString();
                body.end_date = end_date.toISOString();
            }
            const res = await sendRequest(`/lead/list`, 'GET', body);
            handleApiResponse(res);
            if (res.data.type === "success") {
                setCsvLeads(res.data.data.docs);
                setTimeout(() => {
                    // Now you can use csvRef to trigger the CSV download
                    dispatch(callCommonAction({ loading1: false }));
                    csvRef.current.link.click();
                }, 1000);
            }
        } catch (error) {
            dispatch(callCommonAction({ loading1: false }));
            console.error('getDataForCsv error:', error);
            toast.error('Something went wrong');
        }
    };

    /** DATA PREPARED FOR CSV **/
    const dataCSV = csvLeads.map((lead) => {
        let noteData = [];
        if (lead.leadQuestions.length > 0) {
            lead.leadQuestions.map(async (leadQuestion) => {
                if (leadQuestion.value_type == 'select' || leadQuestion.value_type == 'radio') {
                    leadQuestion.question_options.map(async (leadQuesOpt) => {
                        leadQuestion.question.question_options.filter((item) => {
                            return item._id == leadQuesOpt ? noteData.push({ [leadQuestion.question.name]: [leadQuestion.question.name] == 'Price' ? item.value : item.label }) : '';
                        });
                    });
                } else {
                    noteData.push({ [leadQuestion.question.name]: leadQuestion.value });
                }
            });
        }

        return {
            'name': lead.customer_fullname,
            'email': lead.email,
            'phone': lead.phone,
            'dateOfBirth': moment(lead.dateOfBirth).format('MM-DD-YYYY'),
            'postalCode': lead.postalCode,
            'carriers': lead.carriers.map((carrier) => carrier.label).filter(Boolean).join(', '),
            'policies': lead.policies.map((policy) => policy.name).filter(Boolean).join(', '),
            'state': !lead.state ? null : lead.state.label,
            'offers': lead.offers.map((item) => item.name).filter(Boolean).join(', '),
            'category': !lead.category ? null : lead.category.label,
            'notes': noteData.map(obj => `${Object.keys(obj)[0]}: ${Object.values(obj)[0]}`).join(', '),
            'health': !lead.health ? null : lead.health.label,
            'lowestPrice': lead.lowestPrice,
            'heighestPrice': lead.heighestPrice,
            'status': lead.status === 1 ? 'Active' : 'Inactive'
        };
    });

    /** Added Header Here ***/
    const headersCSV = [
        { label: 'Name', key: 'name' },
        { label: 'Email', key: 'email' },
        { label: 'phone', key: 'phone' },
        { label: 'Date Of Birth', key: 'dateOfBirth' },
        { label: 'Postal Code', key: 'postalCode' },
        { label: 'Carriers', key: 'carriers' },
        { label: 'Policies', key: 'policies' },
        { label: 'State', key: 'state' },
        { label: 'Offers', key: 'offers' },
        { label: 'Category', key: 'category' },
        { label: 'Notes', key: 'notes' },
        { label: 'Health', key: 'health' },
        { label: 'Lowest Price', key: 'lowestPrice' },
        { label: 'Heighest Price', key: 'heighestPrice' },
        { label: 'Status', key: 'status' },
    ];


    /***** Get Api Key Data ***/
    const getLeadRelatedData = async () => {
        try {
            dispatch(callCommonAction({ contentLoading: true }));
            const res = await sendRequest('/user/lead-related-data', 'GET');
            dispatch(callCommonAction({ contentLoading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                const userCarriers = res.data.carriers.filter((carr) => {
                    return res.data.data.carriers.includes(carr._id);
                });
                const userPolicies = res.data.policies.filter((policy) => {
                    return res.data.data.policies.includes(policy._id);
                });
                const userOffers = res.data.offers.filter((policy) => {
                    return res.data.data.offers.includes(policy._id);
                });
                setCarriers(userCarriers);
                setPolicies(userPolicies);
                setOffers(userOffers);
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ contentLoading: false }));
        }
    };



    /** Get leads ***/
    const getLeads = async (page, status, start_date, end_date, policy, carrier, offer) => {
        try {
            dispatch(callCommonAction({ loading: true, breadCrumb: ['Lead', 'List'] }));
            let body = { page: page, limit: paginationData.perpage, search_string: filter.search_string, status: status, sort_field_name: filter.sort_field_name, sort_order: filter.sort_order, policy: policy, carrier: carrier, offer: offer };
            if ((start_date !== null && start_date instanceof Date && !isNaN(start_date.getTime())) && (end_date !== null && end_date instanceof Date && !isNaN(end_date.getTime()))) {
                body.start_date = start_date.toISOString();
                body.end_date = end_date.toISOString();
            }
            const res = await sendRequest(`/lead/list`, 'GET', body);
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                setLeads(res.data.data.docs);
                setPaginationData({ ...paginationData, totalCount: res.data.data.totalDocs, page: res.data.data.page });
            }
        } catch (error) {

            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading: false }));
        }
    };


    const Link = ({ id, children, title }) => (
        <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
            <span className="cursor-pointer limit-td text-truncate" href={undefined}>{children}</span>
        </OverlayTrigger>
    );

    /** Render Table Body ***/
    const renderTabeBody = () => {
        return leads.map((lead) => {
            let badgeCls = lead.status === 1 ? 'success' : 'danger';
            return (
                <tr key={lead.id}>
                    <td className="min-w-130px"> <span className='limit-td'>{lead.customer_fullname}</span></td>
                    <td className="min-w-130px"> <span className='limit-td'>{lead.email}</span></td>
                    <td className="min-w-130px"> <span>{lead.phone}</span></td>
                    <td className="min-w-130px"><span className='limit-td'>{moment(new Date(lead.created_at)).format('MM/DD/YYYY')}</span></td>
                    <td className="min-w-130px">
                        <Link title={lead.policies.map((policy) => policy.name).filter(Boolean).join(', ')} id={lead.id + '_policy'}>
                            {lead.policies.map((policy) => policy.name).filter(Boolean).join(', ')}
                        </Link>
                    </td>
                    <td className="min-w-130px">
                        <Link title={lead.carriers.map((carrier) => carrier.label).filter(Boolean).join(', ')} id={lead.id + '_carrier'}>
                            {lead.carriers.map((carrier) => carrier.label).filter(Boolean).join(', ')}
                        </Link>
                    </td>
                    <td className="min-w-130px">
                        <Link title={lead.offers.map((offer) => offer.name).filter(Boolean).join(', ')} id={lead.id + '_offer'}>
                            {lead.offers.map((offer) => offer.name).filter(Boolean).join(', ')}
                        </Link>
                    </td>
                    {/* <td className="min-w-125px">{lead?.created_for?.fullname}</td> */}
                    {/* <td className="min-w-125px text-end"> <span className={`text-uppercase badge badge-light-${badgeCls}`}>{lead.status_name}</span></td> */}
                    <td className="text-center">
                        <Dropdown>
                            <Dropdown.Toggle className='btn btn-light btn-active-light-primary btn-sm' id="dropdown-basic">
                                Actions
                            </Dropdown.Toggle>

                            <Dropdown.Menu align={'end'}>
                                <Dropdown.Item href={undefined} onClick={() => navigate(appRoutes.leadDetailRoute + '/' + lead._id)}>View</Dropdown.Item>
                                <Dropdown.Item href={undefined} onClick={() => navigate(appRoutes.embeddableForm + '/' + lead._id)}>Edit</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            );
        });

    };

    /** Handle On Date Change */
    const handleDateChange = (fieldName, date) => {
        // Ensure date is a valid Date object before updating the state
        if (date instanceof Date && !isNaN(date.getTime())) {
            setFilter({
                ...filter,
                [fieldName]: date,
            });
        }
    };

    return (
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <Helmet>
                <title>iQuote | Leads</title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>

            {/**begin::Post**/}
            <div className="post d-flex flex-column-fluid" id="kt_post">
                {/**begin::Container**/}
                <div id="kt_content_container" className="container-xxl">
                    {/**begin::Card**/}
                    <div className="card">
                        {/**begin::Card header**/}
                        <div className="card-header">
                            {/**begin::Card title**/}
                            <div className="card-title justify-content-between">
                                {/**begin::Search**/}
                                <div className="d-flex align-items-center position-relative my-1">
                                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                    <input type="text" data-kt-user-table-filter="search" value={filter.search_string} onChange={(e) => setFilter({ ...filter, search_string: e.target.value })} className="form-control form-control-solid w-250px ps-13 border" placeholder="Search Lead" />

                                </div>


                                {/**end::Search**/}
                            </div>
                            {/**begin::Card title**/}
                            {/**begin::Card toolbar**/}
                            <div className="card-toolbar">
                                <div className="d-flex flex-wrap justify-content-end gap-2" data-kt-user-table-toolbar="base">
                                    <Dropdown autoClose="outside" className='me-3' show={dropdownOpen} onClose={() => setDropdownOpen(false)}>
                                        <Dropdown.Toggle onClick={() => setDropdownOpen(!dropdownOpen)} className='btn btn-light btn-active-light-primary btn-sm' id="dropdown-basic">
                                            <i className="ki-duotone ki-filter fs-2">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                            </i>Filter
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className='p-0' >
                                            <Dropdown.Item className='p-0'>
                                                <div className="menu menu-sub menu-sub-dropdown w-300px w-md-325px d-block" data-kt-menu="true">
                                                    <div className="px-7 py-5">
                                                        <div className="fs-5 text-gray-900 fw-bold">Filter Options</div>
                                                    </div>
                                                    <div className="separator border-gray-200"></div>
                                                    <div className="px-7 py-5" data-kt-user-table-filter="form">
                                                        {/* <div className="mb-3">
                                                            <label className="form-label fs-6 fw-semibold">Status:</label>
                                                            <select onChange={(e) => setFilter({ ...filter, status: e.target.value })} className="form-select form-select-solid fw-bold" value={filter.status} >
                                                                <option value="all" >All</option>
                                                                <option value="1">Active</option>
                                                                <option value="0">Inactive</option>
                                                            </select>
                                                        </div> */}
                                                        <div className="mb-3">
                                                            <label className="form-label fs-6 fw-semibold">Select offer:</label>
                                                            <select className="form-select form-select-solid fw-bold" value={filter.offer} onChange={(e) => setFilter({ ...filter, offer: e.target.value })}>
                                                                <option value="">Select an option</option>
                                                                {offers.map((offer) => (
                                                                    <option key={offer._id} value={offer._id}> {offer.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label fs-6 fw-semibold">Select carrier:</label>
                                                            <select className="form-select form-select-solid fw-bold" value={filter.carrier} onChange={(e) => setFilter({ ...filter, carrier: e.target.value })}>
                                                                <option value="">Select an option</option>
                                                                {carriers.map((carrier) => (
                                                                    <option key={carrier._id} value={carrier._id}> {carrier.label}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label fs-6 fw-semibold">Select policy:</label>
                                                            <select className="form-select form-select-solid fw-bold" value={filter.policy} onChange={(e) => setFilter({ ...filter, policy: e.target.value })}>
                                                                <option value="">Select an option</option>
                                                                {policies.map((policyData) => (
                                                                    <option key={policyData._id} value={policyData._id}> {policyData.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label fs-6 fw-semibold d-block">Select start date:</label>
                                                            <DatePicker
                                                                selected={filter.start_date}
                                                                onChange={(date) => handleDateChange('start_date', date)}
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                placeholderText="Select Start Date"
                                                                className="form-control fw-bold"
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label fs-6 fw-semibold d-block">Select end date:</label>
                                                            <DatePicker
                                                                selected={filter.end_date}
                                                                onChange={(date) => handleDateChange('end_date', date)}
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                placeholderText="Select End Date"
                                                                className="form-control fw-bold"
                                                            />
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <button className="btn btn-primary fw-semibold px-6" onClick={applyFilter}  >Apply</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>


                                    <button className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6 btn-sm" onClick={clearFilter}  >
                                        <i className="ki-duotone ki-arrows-circle">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </i>
                                        Clear
                                    </button>

                                    <CsvExportButton data={dataCSV} headers={headersCSV} filename={'exported-data.csv'} />

                                </div>
                            </div>
                            {/**end::Card toolbar**/}
                        </div>
                        {/**end::Card header**/}
                        {/**begin::Card body**/}
                        <div className="card-body table-frame py-4 pt-0">
                            {/**begin::Table**/}
                            <div className="table-responsive">
                                <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_leads">
                                    <thead>
                                        <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                            <th className={`min-w-130px sorting ${filter.sort_field_name === 'fullName' ? Boolean(filter.sort_order) ? 'sorting_desc' : 'sorting_asc' : null}`} onClick={() => setFilter({ ...filter, sort_field_name: 'fullName', sort_order: !filter.sort_order })}>Customer Name</th>
                                            <th className="min-w-130px">Email</th>
                                            <th className="min-w-130px">Phone</th>
                                            <th className={`min-w-130px sorting ${filter.sort_field_name === 'created_at' ? Boolean(filter.sort_order) ? 'sorting_desc' : 'sorting_asc' : null}`} onClick={() => setFilter({ ...filter, sort_field_name: 'created_at', sort_order: !filter.sort_order })}>Created date</th>
                                            <th className="min-w-130px">Policies</th>
                                            <th className="min-w-130px">Carriers</th>
                                            <th className="min-w-130px">Offers</th>
                                            {/* <th className="min-w-125px">Agent</th> */}
                                            {/* <th className="min-w-125px text-end">Status</th> */}
                                            <th className="min-w-130px text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-gray-600 fw-semibold">
                                        {leads.length > 0 ? renderTabeBody() : <tr>
                                            <td colSpan={8} className='text-center'>No result found!</td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                            {/**end::Table**/}
                            <Pagination className="pagination-bar" currentPage={paginationData.page} totalCount={paginationData.totalCount}
                                pageSize={paginationData.perpage} onPageChange={page => getLeads(page, filter.status, filter.start_date, filter.end_date, filter.policy, filter.carrier, filter.offer)}
                            />
                        </div>
                        {/**end::Card body**/}
                    </div>
                    {/**end::Card**/}
                </div>
                {/**end::Container**/}
            </div>
            {/**end::Post**/}
        </div >
    );
};

export default List;
