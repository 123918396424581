import React, { useState } from 'react';

const FormQuestion = ({ formData, setFormData, setAttribute, setFormErrors, formErrors, attribute, prevStep, nextStep }) => {


    const chunkSize = 4; // Set the number of questions to display per step
    const [currentStep, setCurrentStep] = useState(0);

    /* handle on change input  */
    const handleInputChange = (item, e) => {
        let value = e.target.value;
        const id = item._id;
        const type = item.type;

        if (item.name == "Gender" || item.name == "Smoker") {
            const selected = item.question_options.find(option => option._id === value);
            let name = item.name == "Gender" ? 'sex' : 'smoker';
            setFormData((prevFormData) => ({
                ...prevFormData,
                'form1': {
                    ...prevFormData.form1,
                    [name]: selected.value,
                },
            }));
        }

        if (item.name == "Coverage") {
            const selected = item.question_options.find(option => option._id === value);
            setFormData((prevFormData) => ({
                ...prevFormData,
                'form2': {
                    ...prevFormData.form2,
                    price: selected.value,
                },
            }));
        }

        // console.log(id, 'id', type, 'type', item, "item", value, "value");
        const newStateType = [...attribute[type]];

        const newValue = { [id]: value };
        const i = newStateType.findIndex(obj => Object.keys(obj)[0] === Object.keys(newValue)[0]);
        if (i !== -1) {
            newStateType[i] = newValue;
        } else {
            newStateType.push(newValue);
        }
        setAttribute(prevState => ({ ...prevState, [type]: newStateType }));

        if (!value || value <= '0') {
            //console.log(value,'value')
            let msg = 'This field is required';
            if (value <= '0' && value != '') {
                msg = `This field is must be greater than 0.`;
            }
            setFormErrors({ ...formErrors, [id]: msg });
        } else {
            const { [id]: _, ...rest } = formErrors;
            setFormErrors(rest);
        }

    };

    const findIndexById = (type, _id) => {
        let _index_ = null;
        if (attribute?.[type].length > 0) {
            const newStateType = [...attribute?.[type]];
            _index_ = newStateType.findIndex(obj => Object.keys(obj)[0] === _id);
        }
        return _index_;
    };

    const renderCurrentStep = () => {
        const start = currentStep * chunkSize;
        const end = start + chunkSize;
        const currentStepData = formData.form4.slice(start, end);

        return currentStepData.map((item, index) => (
            <React.Fragment key={index}>{renderFormElement(item, index)}</React.Fragment>
        ));
    };

    const handleNext = async (nxtStp) => {
        if (validateStep5()) {
            if (nxtStp) {
                nextStep();
            } else {
                setCurrentStep((prevStep) => prevStep + 1);
            }
        }
    };

    const handlePrev = (preStp) => {
        if (preStp) {
            prevStep();
        } else {
            setCurrentStep((prevStep) => Math.max(0, prevStep - 1));
        }
    };

    const validateStep5 = () => {
        let erObj = {};
        let allValidField = true;

        const currentStepQuestions = formData.form4.slice(currentStep * chunkSize, (currentStep + 1) * chunkSize);

        currentStepQuestions.forEach((item) => {
            let foundObject = getKeyAndValueById(attribute[item.type], item._id);

            if (Object.keys(foundObject).length !== 0) {
                if (foundObject.value === '' || foundObject.value <= '0') {
                    allValidField = false;
                    let msg = 'This field is required';
                    if (foundObject.value <= '0' && foundObject.value !== '') {
                        msg = `This field must be greater than 0.`;
                    }
                    erObj[item._id] = msg;
                } else {
                    erObj[item._id] = '';
                }
            } else {
                allValidField = false;
                erObj[item._id] = 'This field is required';
            }
        });

        // Additional validation for other fields outside the current step
        if (currentStep === 4) { // Assuming the last step is step 5
            if (formData.form1.sex === "") {
                erObj['sex'] = 'This field is required';
                allValidField = false;
            }
            if (formData.form1.smoker === "") {
                erObj['smoker'] = 'This field is required';
                allValidField = false;
            }
        }

        setFormErrors(erObj);
        return allValidField;

    };


    // Function to get key and value based on ID
    const getKeyAndValueById = (arr, targetId) => {
        // Find the object with the matching ID
        const foundObject = arr.find(obj => Object.keys(obj)[0] === targetId);

        // Check if the object was found
        if (foundObject) {
            // Extract key and value from the found object
            const key = Object.keys(foundObject)[0];
            const value = foundObject[key];

            // Return key and value
            return { key, value };
        } else {
            // Return null or handle the case where no matching ID was found
            return {};
        }
    };


    const renderFormElement = (item, index) => {

        const id = item._id;

        const type = item.type;

        let _index_ = findIndexById(type, id);

        const validationError = formErrors[id];

        switch (item.type) {

            case 'number':
                return (
                    <div className="fv-row mb-4 col">
                        <h4>{item.name}</h4>
                        <input
                            placeholder={item.name}
                            type={item.type}
                            name={id}
                            className="form-control bg-transparent"
                            value={attribute?.[type]?.[_index_]?.[id] || ''}
                            onChange={(e) => handleInputChange(item, e)}
                        />
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                <span role="alert">{validationError}</span>
                            </div>
                        </div>
                    </div>
                );

            case 'text':
                return (
                    <div className="fv-row mb-4 col">
                        <h4>{item.name}</h4>
                        <input
                            placeholder={item.name}
                            type={item.type}
                            name={id}
                            className="form-control bg-transparent"
                            value={attribute?.[type]?.[_index_]?.[id] || ''}
                            onChange={(e) => handleInputChange(item, e)}
                        />
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                <span role="alert">{validationError}</span>
                            </div>
                        </div>
                    </div>
                );
            case 'select':
                return (
                    <div className="fv-row mb-4 col">
                        <h4>{item.name}</h4>
                        <select
                            placeholder={item.name}
                            name={id}
                            className="form-control bg-transparent"
                            value={attribute?.[type]?.[_index_]?.[id] || ''}
                            onChange={(e) => handleInputChange(item, e)}
                        >
                            <option value="">Select Option</option>
                            {
                                item.question_options.map((option, optInd) => {
                                    return (
                                        <option key={optInd} value={option._id}>{option.label}</option>
                                    );
                                })
                            }
                        </select>
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                <span role="alert">{validationError}</span>
                            </div>
                        </div>
                    </div>
                );
            case 'radio':
                return (
                    <div className="fv-row mb-4 col">
                        <h4 className='mb-4'>{item.name}</h4>
                        <div className='d-flex flex-wrap'>
                            {
                                item.question_options.map((option, optInd) => {
                                    return (
                                        <div className="form-check form-check-solid mb-5 ps-0 me-5 pe-5" key={option._id}>
                                            <label className='d-block fw-bold mb-2'> {option.label} </label>
                                            <input
                                                placeholder={item.name}
                                                id={option._id}
                                                type={item.type}
                                                name={id}
                                                value={option._id}
                                                className="form-check-input m-0"
                                                checked={attribute?.[type]?.[_index_]?.[id] == option._id}
                                                onChange={(e) => handleInputChange(item, e)}
                                            />
                                        </div>

                                    );
                                })
                            }
                        </div>

                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                <span role="alert">{validationError}</span>
                            </div>
                        </div>
                    </div>
                );
            default:
                return <p>Default Component</p>;
        }
    };


    return (
        <div>
            {/* {console.log('attribute', attribute)}
            {console.log('formData', formData)} */}
            {renderCurrentStep()}
            <div className="row">
                <div className="col"><button type="button" className="btn btn-lg w-100 mb-5 bg-primary-subtle text-body bg-hover-primary text-hover-white" onClick={() => handlePrev(currentStep === 0 ? true : false)}><i className="ki-duotone ki-left p-0 fs-4"></i>Previous</button></div>
                <div className="col"><button type="button" className="btn btn-lg btn-primary w-100 mb-5" onClick={() => handleNext(currentStep === Math.ceil(formData.form4.length / chunkSize) - 1) ? true : false} >Next<i className="ki-duotone ki-right p-0 fs-4"></i></button></div>
            </div>
        </div>
    );

};

export default FormQuestion;
