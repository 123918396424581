import STRINGS from '../../common/strings/strings';
import Helper from '../../apis/Helper';
let isAdminUrl = window.location.href.includes('admin');

function validateUserDetailFormData(userDetail, fieldName, isValidPhoneNumber) {
    const { display_name, first_name, last_name, email, phonenumber, insurance_url,calendy_url } = userDetail;
    // console.log('fieldName',fieldName,'calendy_url',calendy_url);
    let errorMsg = ''; let errors = {}; let formVaildCheck = true;
    const phoneRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    // Regular expression for a simple URL validation
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

    if (display_name === '' && (fieldName == 'display_name' || fieldName == '')) {
        errorMsg = !display_name ? STRINGS.displayNameReq : '';
        errors.display_name = errorMsg;
        formVaildCheck = false;
    }
    if (last_name === '' && (fieldName == 'last_name' || fieldName == '')) {
        errorMsg = !last_name ? STRINGS.lastNameReq : '';
        errors.last_name = errorMsg;
        formVaildCheck = false;
    }
    if (first_name === '' && (fieldName == 'first_name' || fieldName == '')) {
        errorMsg = !first_name ? STRINGS.firstNameReq : '';
        errors.first_name = errorMsg;
        formVaildCheck = false;
    }

    if (!urlRegex.test(calendy_url) && (fieldName == 'calendy_url' || fieldName == '') && !isAdminUrl) {
        errorMsg = !calendy_url ? STRINGS.insuranceUrlReq : STRINGS.invalidInsuranceUrl;
        errors.calendy_url = errorMsg;
        formVaildCheck = false;
    }

    if ((fieldName == 'email' || fieldName == '') && (!Helper.validateEmail(email))) {
        errorMsg = !email ? STRINGS.emailReq : STRINGS.invalidEmail;
        formVaildCheck = false;
        errors.email = errorMsg;
    }
    if ((fieldName == 'phonenumber' || fieldName == '') && (!isValidPhoneNumber(phonenumber) )) {
        errorMsg = "Please enter a valid phone number";
        errors.phonenumber = errorMsg;
        formVaildCheck = false;
    }

    return { formVaildCheck: formVaildCheck, errors: errors };
}


function validateChangePasswordFormData(userDetail, fieldName) {
    const { currentpassword, newpassword, confirmpassword } = userDetail;
    let errorMsg = ''; let errors = {}; let formVaildCheck = true;

    if ((fieldName == 'currentpassword' || fieldName == '') && (!Helper.validatePassword(currentpassword))) {
        errorMsg = currentpassword == '' ? STRINGS.currentPasswordReq : STRINGS.invalidPassword;
        errors.currentpassword = errorMsg;
        formVaildCheck = false;
    }

    if ((fieldName == 'newpassword' || fieldName == '') && (!Helper.validatePassword(newpassword))) {
        errorMsg = newpassword == '' ? STRINGS.newPasswordReq : STRINGS.invalidPassword;
        errors.newpassword = errorMsg;
        formVaildCheck = false;
    }

    if ((fieldName == 'confirmpassword' || fieldName == '') && !Helper.validatePassword(confirmpassword)) {
        errorMsg = confirmpassword == '' ? STRINGS.confirmPasswordReq : STRINGS.invalidConfirmPassword;
        errors.confirmpassword = errorMsg;
        formVaildCheck = false;
    }

    if (confirmpassword !== '' && newpassword !== '' && (fieldName == 'confirmpassword' || fieldName == '')) {
        if (confirmpassword !== newpassword) {
            errors.confirmpassword = STRINGS.passwordMissMatch;
            formVaildCheck = false;
        }
    }
    return { formVaildCheck: formVaildCheck, errors: errors };
}

export { validateUserDetailFormData, validateChangePasswordFormData };