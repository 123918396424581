import React, { Suspense, useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { callCommonAction } from './redux/Common/CommonReducer';

import UserRoute from './routes/UserRoute';
import AdminRoute from './routes/AdminRoute';


function App() {
  const dispatch = useDispatch();
  const isAdminUrl = window.location.href.includes('admin')
  const { isLoggedIn,isAdmin } = useSelector((state) => state.common);
  /** Only Load Time Set State From LocalStorage */
  useEffect(() => {
    let raiseNotificationBell = localStorage.raiseNotificationBell != undefined ? localStorage.raiseNotificationBell == "true" ? true : false : false;
    if (localStorage.token !== undefined && localStorage.token !== null) {
      dispatch(callCommonAction({ 
        isLoggedIn: true, 
        token: localStorage.token, 
        user: JSON.parse(localStorage.user),
        isAdmin : localStorage.isAdmin === undefined ? false : localStorage.isAdmin === 'false' ? false : true }));
    }
    //without user logged in we also need raise Notification Bell
    dispatch(callCommonAction({ raiseNotificationBell: raiseNotificationBell }));
  }, [isLoggedIn, isAdmin]);

  return (
    <>
      <BrowserRouter>
        <ToastContainer autoClose={1500} />
        <Suspense>
          {isAdminUrl ? <AdminRoute /> : <UserRoute />}
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
