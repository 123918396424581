import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {

    const navigate = useNavigate();

    return (
        <div className="mb-5">
            <span className="btn btn-primary btn-sm" onClick={() => navigate(-1)}>
                <i className="ki-duotone ki-left"></i>Back
            </span>
        </div>
    );
};

export default BackButton;
