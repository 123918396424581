import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { handleApiResponse } from '../../apis/apiUtils';
import { toast } from 'react-toastify';
import { sendRequest } from '../../apis/APIs';
import appSettings from '../../configs/AppConfig';
import moment from 'moment';
import Pagination from '../../common/Pagination/pagination';
import { Helmet } from 'react-helmet';

const List = () => {
    const [errorList, setErrorList] = useState([]);
    const { isAdmin } = useSelector((state) => state.common);
    const dispatch = useDispatch();
    const [paginationData, setPaginationData] = useState({ perpage: appSettings.recordsPerPageTable, page: 1, totalCount: 0 });
    const [filter, setFilter] = useState({ search_string: '', status: '' });


    useEffect(() => {
        getErrorReport();
    }, [paginationData.page]);

    /** Color Code And Their Status ***/
    const colorCode = {
        200: { className: 'success' },
        404: { className: 'warning' },
        500: { className: 'danger' },
    };
    /** Get Error Report For Admin */
    const getErrorReport = async () => {

        try {
            dispatch(callCommonAction({ loading: true, breadCrumb: ['Error Report', 'List'] }));
            let reqUrl = isAdmin ? '/admin/error-report' : `/user/error-report`;
            const res = await sendRequest(reqUrl, 'GET', { page: paginationData.page, limit: paginationData.perpage, search_string: filter.search_string, status: filter.search_string });
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                setErrorList(res.data.data.docs);
                setPaginationData({ ...paginationData, totalCount: res.data.data.totalDocs });
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    /** Render Error Table Body Content */
    const renderTabeBody = () => {
        return errorList.map((error) => {
            return (
                <tr key={error._id}>
                    <td className="min-w-70px" > <div className={`badge badge-light-${colorCode[error.error_code]?.className}`}>{error.error_code}</div></td>
                    <td>{error.error_msg}</td>
                    <td>{error.created_by ? error.created_by.email : '---'}</td>
                    <td>{error.created_by ? error.created_by.fullname : '---'}</td>
                    <td className="pe-0 text-end min-w-200px">{moment(error.created_at).format('MM/DD/YYYY HH:mm:ss')}</td>
                </tr>
            );
        });

    };

    return (
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <Helmet>
				<title>iQuote | Error List</title>
				<meta name="description" content="Description goes here" />
				<meta name="keywords" content="Game, Entertainment, Movies" />
			</Helmet>
            {/**begin::Post**/}
            <div className="post d-flex flex-column-fluid" id="kt_post">
                {/**begin::Container**/}
                <div id="kt_content_container" className="container-xxl">
                    {/**begin::Card**/}
                    <div className="card ">
                        {/**begin::Card header**/}
                        <div className="card-header">
                            {/**begin::Card title**/}
                            <div className="card-title">
                                <h2>Error Report</h2>
                            </div>
                            {/**end::Card title**/}
                        </div>
                        {/**end::Card header**/}
                        {/**begin::Card body**/}
                        <div className="card-body table-frame py-0">
                            {/**begin::Table wrapper**/}
                            <div className="table-responsive">
                                {/**begin::Table**/}
                                <table className="table align-middle table-row-dashed fw-semibold text-gray-600 fs-6 gy-5" id="kt_table_customers_logs">
                                    {/**begin::Table body**/}
                                    <thead>
                                        <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                            <th className="min-w-125px">Status Code</th>
                                            <th className="min-w-125px">Message</th>
                                            <th className="min-w-125px">Email</th>
                                            <th className="min-w-125px">Name</th>
                                            <th className="text-end min-w-100px">Created Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {errorList.length > 0 ? renderTabeBody() : <tr>
                                            <td colSpan={5} className='text-center'>No result found!</td>
                                        </tr>}
                                    </tbody>
                                    {/**end::Table body**/}
                                </table>
                                {/**end::Table**/}
                                
                            </div>
                            <div className='pag mb-4'>
                                    <Pagination className="pagination-bar" currentPage={paginationData.page} totalCount={paginationData.totalCount}
                                        pageSize={paginationData.perpage} onPageChange={page => setPaginationData({ ...paginationData, page: page })}
                                    />
                                </div>
                            {/**end::Table wrapper**/}
                        </div>
                        {/**end::Card body**/}
                    </div>
                    {/**end::Card**/}
                </div>
            </div>
        </div>
    );
};

export default List;
