import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendRequest } from '../../apis/APIs';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import 'react-toastify/dist/ReactToastify.css'; // import first
import { toast } from 'react-toastify';
import { handleApiResponse } from '../../apis/apiUtils';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Form from 'react-bootstrap/Form';

const ManageCrm = () => {

    const { contentLoading, user } = useSelector((state) => state.common);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorsInfo, setErrorsInfo] = useState({ api_key: '' });
    const [userSetting, setUserSetting] = useState({});


    useEffect(() => {
        getUserSetting();
    }, []);


    /***** Get Api Key Data ***/
    const getUserSetting = async () => {
        try {
            dispatch(callCommonAction({ contentLoading: true, breadCrumb: ['Crm', 'Settings'] }));
            const res = await sendRequest('/user/crm-setting', 'GET');
            dispatch(callCommonAction({ contentLoading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                setUserSetting(res.data.data);
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ contentLoading: false }));
        }
    };

    /***** Update Api Key Data ***/
    const updateApiKeyData = async (e) => {
        e.preventDefault();
        if (checkFormIsValid(userSetting, "")) {
            try {
                dispatch(callCommonAction({ contentLoading: true }));
                const res = await sendRequest('/user/crm-setting', 'POST', userSetting);
                dispatch(callCommonAction({ contentLoading: false }));
                handleApiResponse(res);
                if (res.data.type === "success") {
                    setUserSetting(res.data.data);
                    localStorage.setItem('user', JSON.stringify(res.data.data));
                    dispatch(callCommonAction({ user: res.data.data }));
                }
            } catch (error) {
                toast.error('Something went wrong');
                dispatch(callCommonAction({ contentLoading: false }));
            }
        }
    };

    // Function to handle toggle for each notification type (01-01-2024)
    const handleToggle = async (type, value) => {
        setUserSetting((prev) => ({
            ...prev,
            notification_settings: {
                ...prev.notification_settings,
                [type]: !value
            }
        }));
    };

    /** Handle Input Change Manage Heere */
    const handleChangeInput = (e) => {
        const { name, value } = e.target;

        setUserSetting((prev) => ({
            ...prev,
            crm_settings: {
                ...prev.crm_settings,
                [name]: value
            }
        }));

        checkFormIsValid({ ...userSetting, crm_settings: { ...userSetting.crm_settings, [name]: value } }, name);
    };


    const checkFormIsValid = (userSetting, fieldName) => {
        const { crm_settings } = userSetting;
        let errorMsg = ''; let errors = {}; let formVaildCheck = true;
        if ((fieldName == 'api_key' || fieldName == '') && (!crm_settings.api_key)) {
            errorMsg = !crm_settings.api_key ? 'This field is required' : '';
            formVaildCheck = false;
            errors.api_key = errorMsg;
        }
        setErrorsInfo(errors);
        return formVaildCheck;
    };

    return (
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            {console.log(userSetting, 'userSetting')}
            <Helmet>
                <title>iQuote | Crm Management</title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>
            {/**begin::Post**/}
            <div className="post d-flex flex-column-fluid" id="kt_post">
                {/**begin::Container**/}
                <div id="kt_content_container" className="container-xxl">
                    {/**begin::Card**/}
                    <div className="card">
                        {/**begin::Card header**/}
                        <div className="card-header">
                            {/**begin::Card title**/}
                            <div className="card-title">
                                <h4>Agent CRM/GoHighLevel Settings</h4>
                            </div>
                        </div>
                        {/**end::Card header**/}
                        {/**begin::Card body**/}
                        <div className="card-body">
                            <form onSubmit={updateApiKeyData}>
                                <div className="row">

                                    <div className='col-12'>
                                        <h5 className='mb-5'>Need help finding your API Key? Here's a quick video to show you where to find it in your account</h5>
                                        <iframe className='api-video mb-5' src="https://www.loom.com/embed/d012be0a6e834b74908f3c791613481b?sid=26b50bb1-e8e8-47b8-94a1-190dfb5bfa66" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                                        <hr></hr>
                                    </div>
                                    <div className="col-12 col-md-8">

                                        <div className="fv-row mb-7 fv-plugins-icon-container">
                                            <label className="fs-6 fw-semibold form-label mt-3">
                                                <h5 className="d-flex mt-2">Api Key</h5>
                                            </label>
                                            <div className="d-flex flex-column flex-md-row gap-3">
                                                <input type="text" onChange={handleChangeInput} value={userSetting?.crm_settings?.api_key} className={`form-control form-control-solid ${errorsInfo.api_key ? 'is-invalid' : ''}`} name="api_key" />
                                            </div>

                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    <span role="alert">{errorsInfo.api_key}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-1"></div>
                                    <div className="col-12 col-md-3 switch-col">
                                        <label className="fs-6 fw-semibold form-label mt-3">
                                            <span className=""></span>
                                        </label>
                                        <div className="fv-row mt-8">
                                            <Form.Check
                                                type="switch"
                                                id="enableUploadToCrm"
                                                checked={userSetting?.notification_settings?.enableUploadToCrm || false}
                                                onChange={() => handleToggle('enableUploadToCrm', userSetting?.notification_settings?.enableUploadToCrm || false)}
                                                label="Enable Upload To CRM"
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <button type="submit" className='btn btn-primary btn-sm'>{
                                            contentLoading
                                                ?
                                                <span className="indicator-progress" style={{ display: 'block' }}>
                                                    Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                                :
                                                <span className="indicator-label">Save</span>
                                        }</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/**end::Card body**/}
                    </div>
                    {/**end::Card**/}
                </div>
            </div>
            {/**End::Post**/}
        </div>
    );
};

export default ManageCrm;
