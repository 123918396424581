import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { sendRequest } from '../../apis/APIs';
import appRoutes from '../../configs/AppRoutes';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validateLoginFormData } from './Validation';
import { handleApiResponse } from '../../apis/apiUtils';
import { Helmet } from 'react-helmet';


const Login = () => {
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState({ email: '', password: '' });
  const [errorsInfo, setErrorsInfo] = useState({});
  const { loading, isLoggedIn, isAdmin } = useSelector((state) => state.common);
  const isAdminUrl = window.location.href.includes('admin');
  const dispatch = useDispatch();
  const { state } = useLocation();


  useEffect(() => {
    if (isLoggedIn) {
      if (state != null) {
        return navigate(appRoutes.checkoutRoute + '/' + state.slug, { state: { 'last_route': 'login' } });
      }
      return isAdmin ? navigate(appRoutes.adminDashboardRoute) : navigate(appRoutes.dashboardRoute);
    }
  }, [isLoggedIn]);

  const handleChange = (e) => {
    userDetail[e.target.name] = e.target.value;
    setUserDetail(userDetail);
    checkFormIsValid(e.target.name);
  };

  /* Validation implemented here */
  const checkFormIsValid = (fieldName) => {
    const res = validateLoginFormData(userDetail, fieldName);
    setErrorsInfo(res.errors);
    return res.formVaildCheck;
  };

  const loginUserSubmit = async (e) => {
    e.preventDefault();
    if (checkFormIsValid('')) {
      try {
        dispatch(callCommonAction({ loading: true }));
        let reqUrl = isAdminUrl ? '/admin/login' : `/user/login`;
        const res = await sendRequest(reqUrl, 'POST', userDetail);
        dispatch(callCommonAction({ loading: false }));
        handleApiResponse(res);
        if (res.data.type === "success") {
          setUserDetail({ email: '', password: '' });
          localStorage.setItem("token", res.data.access_token);
          localStorage.setItem('user', JSON.stringify(res.data.data));
          localStorage.setItem('isAdmin', res.data.data.role === 1);
          dispatch(callCommonAction({ isLoggedIn: true, user: res.data.data, token: res.data.access_token, isAdmin: res.data.data.role === 1 }));
        }
      } catch (error) {
        toast.error(error.message);
        dispatch(callCommonAction({ loading: false }));
      }
    }
  };

  return (
    <div>
      <Helmet>
        <title>iQuote | Login Form</title>
        <meta name="description" content="Description goes here" />
        <meta name="keywords" content="Game, Entertainment, Movies" />
      </Helmet>
      <form
        className="form w-100"
        noValidate
        onSubmit={loginUserSubmit}
        id="kt_sign_in_form"
      >
        <div className="text-center mb-11">
          <h1 className="text-gray-900 fw-bolder mb-3">Sign In</h1>
        </div>
        <div className="fv-row mb-8">
          <input
            type="text"
            placeholder="Email"
            name="email"
            autoComplete="off"
            className={`form-control bg-transparent ${errorsInfo.email ? 'is-invalid' : ''}`}
            onChange={handleChange}
            value={userDetail.email}
          />
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{errorsInfo.email}</span>
            </div>
          </div>
        </div>
        <div className="fv-row mb-3">
          <input
            type="password"
            placeholder="Password"
            name="password"
            autoComplete="off"
            className={`form-control bg-transparent ${errorsInfo.password ? 'is-invalid' : ''}`}
            onChange={handleChange}
            value={userDetail.password}
          />
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{errorsInfo.password}</span>
            </div>
          </div>
        </div>

        <div className={`d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8`}>
          <div></div>
          <a
            href={undefined}
            onClick={() => navigate(isAdminUrl ? appRoutes.adminForgotPasswordRoute : appRoutes.forgotPasswordRoute)}
            className="link-primary cursor-pointer"
          >
            Forgot Password?
          </a>
        </div>

        <div className="d-grid mb-10">
          <button
            type="submit"
            id="kt_sign_up_submit"
            className="btn btn-lg btn-primary w-100 mb-5"
            disabled={loading ? true : false}
          >
            {
              loading
                ?
                <span className="indicator-progress" style={{ display: 'block' }}>
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
                :
                <span className="indicator-label">Sign In</span>
            }

          </button>
        </div>
        {
          isAdminUrl ? null :
            <div className="text-gray-500 text-center fw-semibold fs-6">
              Not a Member yet?
              <a
                href={undefined}
                onClick={() => navigate(appRoutes.registerRoute)}
                className="link-primary cursor-pointer ms-1"
              >
                Sign up
              </a>
            </div>
        }
      </form>
    </div>
  );
};

export default Login;
