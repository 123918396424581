import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { useDispatch, useSelector } from 'react-redux';
import { sendRequest } from '../../apis/APIs';
import { handleApiResponse } from '../../apis/apiUtils';
import { toast } from 'react-toastify';
import appRoutes from '../../configs/AppRoutes';
import BackButton from '../../common/BackButton';
import Pagination from '../../common/Pagination/pagination';
import appSettings from '../../configs/AppConfig';
import moment from 'moment';
import { Helmet } from 'react-helmet';

const Detail = () => {
    const { isLoggedIn } = useSelector((state) => state.common);
    const { userId } = useParams();
    const dispatch = useDispatch();
    const [userDetail, setUserDetail] = useState({});
    const [leads, setLeads] = useState({});
    const [paginationData, setPaginationData] = useState({ perpage: appSettings.recordsPerPageTable, page: 1, totalCount: 0 });
    const [filter, setFilter] = useState({ search_string: '', status: 'all' });


    useEffect(() => {
        if (isLoggedIn) {
            getUser();
        }
    }, []);
    useEffect(() => {
        if (isLoggedIn && filter.search_string.length > 2 || filter.search_string === "") getUserLeads(1, filter.status);
    }, [filter.search_string]);

    /** Applied Filter Here */
    const applyFilter = () => {
        setPaginationData({ ...paginationData, page: 1 });
        getUserLeads(1, filter.status);
    };

    const clearFilter = () => {
        setPaginationData({ ...paginationData, page: 1 });
        setFilter({ search_string: '', status: 'all' });
        getUserLeads(1, 'all');
    };


    const getUser = async () => {
        try {
            dispatch(callCommonAction({ loading: true, breadCrumb: ['Agent', 'Detail', appRoutes.adminUserListRoute] }));
            const res = await sendRequest(`/user/detail/${userId}`, 'GET');
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);

            if (res.data.type === "success") {
                setUserDetail(res?.data?.data);
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading: false }));
        }
    };


    const getUserLeads = async (page, status) => {
        try {
            //dispatch(callCommonAction({ loading: true, breadCrumb: ['Agent', 'Detail', appRoutes.adminUserListRoute] }));
            const res = await sendRequest(`/lead/list`, 'GET', { page: page, limit: paginationData.perpage, search_string: filter.search_string, status: status, lead_create_for: userId });
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);

            if (res.data.type === "success") {
                setLeads(res.data.data.docs);
                setPaginationData({ ...paginationData, totalCount: res.data.data.totalDocs, page: res.data.data.page });
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading: false }));
        }
    };
    const renderTabeBody = () => {
        return leads.map((lead) => {
            let badgeCls = lead.status === 1 ? 'success' : 'danger';
            return (
                <tr key={lead.id}>
                    <td className="min-w-125px"> <span>{lead.customer_fullname}</span></td>
                    <td className="min-w-125px"> <span>{lead.email}</span></td>
                    <td className="min-w-125px"> <span>{lead.phone}</span></td>
                    <td className="min-w-125px">{moment(new Date(lead.created_at)).format('MM/DD/YYYY')}</td>
                    <td className="min-w-125px">{lead.offers.map((item) => item.name).filter(Boolean).join(', ')}</td>
                    {/* <td className="min-w-125px">{lead?.created_for?.fullname}</td> */}
                    <td className="min-w-125px text-center"> <span className={`text-uppercase badge badge-light-${badgeCls}`}>{lead.status_name}</span></td>
                </tr>
            );
        });

    };
    return (
        <>
            <Helmet>
                <title>iQuote | Agent Detail</title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>
            {/*begin::Content*/}
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                {/*begin::Post*/}
                <div className="post d-flex flex-column-fluid" id="kt_post">
                    {/*begin::Container*/}
                    <div id="kt_content_container" className="container-xxl">
                        {/**begin::BackButton**/}
                        <div>
                            {/* Your parent component content */}
                            <BackButton />
                        </div>
                        {/**end::BackButton**/}
                        {/*begin::Navbar*/}
                        <div className="card mb-5 mb-xxl-8">
                            <div className="card-body table-frame pt-9 pb-9 min-h-auto">
                                {/*begin::Details*/}
                                <div className="d-flex flex-wrap flex-sm-nowrap">
                                    {/*begin: Pic*/}
                                    <div className="me-7 mb-2">
                                        <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                            <img src={userDetail && userDetail.user_image ? process.env.REACT_APP_ASSET_ENDPOINT + '/' + userDetail.user_image : '/assets/media/avatars/blank.png'} alt="image" />
                                            <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px" />
                                        </div>
                                    </div>
                                    {/*end::Pic*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1">
                                        {/*begin::Title*/}
                                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                            {/*begin::User*/}
                                            <div className="d-flex flex-column w-100">
                                                {/*begin::Name*/}
                                                <div className="d-flex align-items-center mb-2">
                                                    <span
                                                        className="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                                                    >
                                                        {userDetail?.display_name}
                                                    </span>
                                                </div>
                                                {/*end::Name*/}
                                                {/*begin::Info*/}
                                                <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2 w-100">
                                                    <div className="row w-100">
                                                        <div className="fs-6 fw-semibold mb-5 text-muted col-12 col-md-6 col-xl-3">
                                                            <span className="text-body">First Name: </span>
                                                            {userDetail?.first_name}
                                                        </div>
                                                        <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3">
                                                            <span className="text-body">Last Name: </span>
                                                            {userDetail?.last_name}
                                                        </div>
                                                        <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3">
                                                            <span className="text-body">Display Name: </span>
                                                            {userDetail?.display_name}
                                                        </div>
                                                        <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3">
                                                            <span className="text-body">Email: </span>
                                                            {userDetail?.email}
                                                        </div>
                                                    </div>
                                                    <div className="row w-100">
                                                        <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3">
                                                            <span className="text-body">Phone: </span>
                                                            {userDetail?.phonenumber}
                                                        </div>
                                                        <div className="fs-6 fw-semibold mb-5 text-muted col-12 col-md-6 col-xl-3">
                                                            <span className="text-body">Gender: </span>
                                                            {userDetail.gender == '1' ? 'Male' : 'Female'}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*end::Info*/}
                                            </div>
                                            {/*end::User*/}

                                        </div>
                                        {/*end::Title*/}
                                        {/*begin::Stats*/}

                                        {/*end::Stats*/}
                                    </div>
                                    {/*end::Info*/}
                                </div>
                                {/*end::Details*/}
                                {/*begin::Navs*/}

                                {/*begin::Navs*/}
                            </div>
                        </div>
                        {/*end::Navbar*/}
                        {/*begin::Row*/}
                        <div className="row g-5 g-xxl-8">
                            {/*begin::Col*/}
                            <div className="col-xl-12">
                                {/*begin::Feeds Widget 1*/}
                                <div className="card mb-5 mb-xxl-8">
                                    {/*begin::Body*/}
                                    <div className="card-body table-frame">
                                        {/**begin::Card**/}
                                        <div className="card">
                                            {/**begin::Card header**/}
                                            <div className="card-header">
                                                {/**begin::Card title**/}
                                                <div className="card-title">
                                                    {/**begin::Search**/}
                                                    <div className="d-flex align-items-center position-relative my-1">
                                                        <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                        </i>
                                                        <input type="text" data-kt-user-table-filter="search" value={filter.search_string} onChange={(e) => setFilter({ ...filter, search_string: e.target.value })} className="form-control form-control-solid w-250px ps-13 border" placeholder="Search Lead" />
                                                    </div>
                                                    {/**end::Search**/}
                                                </div>
                                                {/**begin::Card title**/}
                                                {/**begin::Card toolbar**/}
                                                <div className="card-toolbar">


                                                    {/* <div className="d-flex align-items-center">
                                                        <div className="d-flex align-items-center me-2">
                                                            <label className="form-label fw-semibold mb-0 me-2">Status:</label>
                                                            <div >
                                                                <select className="form-select form-select-solid fw-bold" value={filter.status} onChange={(e) => setFilter({ ...filter, status: e.target.value })} >
                                                                    <option value="all">All</option>
                                                                    <option value="1">Active</option>
                                                                    <option value="0">Inactive</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <button type="reset" className="btn btn-light btn-active-light-primary me-2 px-3" onClick={clearFilter}>
                                                                <i className="ki-duotone ki-arrows-circle fs-1 px-0">
                                                                    <span className="path1"></span>
                                                                    <span className="path2"></span>
                                                                </i>
                                                            </button>
                                                            <button type="submit" className="btn btn-primary" onClick={applyFilter}>Apply</button>
                                                        </div>
                                                    </div> */}



                                                    {/**end::Toolbar**/}
                                                    {/**begin::Group actions**/}
                                                    <div className="d-flex justify-content-end align-items-center d-none" data-kt-user-table-toolbar="selected">
                                                        <div className="fw-bold me-5">
                                                            <span className="me-2" data-kt-user-table-select="selected_count"></span>Selected</div>
                                                        <button type="button" className="btn btn-danger" data-kt-user-table-select="delete_selected">Delete Selected</button>
                                                    </div>
                                                    {/**end::Group actions**/}
                                                </div>
                                                {/**end::Card toolbar**/}
                                            </div>
                                            {/**end::Card header**/}
                                            {/**begin::Card body**/}
                                            <div className="card-body table-frame py-4">
                                                {/**begin::Table**/}
                                                <div className='table-responsive'>
                                                <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_leads">
                                                    <thead>
                                                        <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">

                                                            <th className="min-w-140px">Customer Name</th>
                                                            <th className="min-w-140px">Customer email</th>
                                                            <th className="min-w-140px">Customer phone</th>
                                                            <th className="min-w-140px">Created date</th>
                                                            <th className="min-w-140px">Offers</th>
                                                            {/* <th className="min-w-125px">Agent</th> */}
                                                            <th className="min-w-125px text-center">Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-gray-600 fw-semibold">
                                                        {leads.length > 0 ? renderTabeBody() : <tr>
                                                            <td colSpan={5} className='text-center'>No result found!</td>
                                                        </tr>}
                                                    </tbody>
                                                </table>
                                                {/**end::Table**/}
                                                <Pagination className="pagination-bar" currentPage={paginationData.page} totalCount={paginationData.totalCount}
                                                    pageSize={paginationData.perpage} onPageChange={page => getUserLeads(page, filter.status)}
                                                />
                                            </div>
                                            </div>
                                            {/**end::Card body**/}
                                        </div>
                                        {/**end::Card**/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*end::Row*/}
                    </div>
                    {/*end::Container*/}
                </div>
                {/*end::Post*/}
            </div>
            {/*end::Content*/}
        </>
    );
};

export default Detail;