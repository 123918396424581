import { toast } from 'react-toastify'; // then this
const handleApiResponse = (res) => {
    switch (res.data.type) {
        case "success":
            handleSuccess(res);
            break;
        case "validation_error":
            handleValidationError(res);
            break;
        case "failure":
            handleFailure(res);
            break;
        default:
            // Handle any other types if needed
            break;
    }
};

const handleSuccess = (res) => {
    if (res.data.message !== '') {
        toast.success(res.data.message, {
            toastId: 'success1',
        });
    }
};

const handleValidationError = (res) => {
    
    // Handle validation error specific logic if needed
    const validationError = res.data.data.errors;

    if (validationError && Object.keys(validationError).length > 0) {
        const firstErrorKey = Object.keys(validationError)[0];
        const firstErrorMessage = validationError[firstErrorKey][0];
        toast.error(firstErrorMessage, {
            toastId: 'success1',
        });
        // Handle validation error specific logic if needed
    } else {
        // Handle in case of unexpected structure or empty validationError
        toast.error('Validation error occurred.', {
            toastId: 'success1',
        });
    }
};

const handleFailure = (res) => {
    console.log(res,'res')
    if (res.data.tokenExpired !== undefined && res.data.tokenExpired) {
        // Token expired, perform logout and cleanup
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        toast.error('Logged out due to token expiration.', {
            toastId: 'success1',
        });
        //setTimeout(() => window.location.reload(), 1000);
    } else {
        toast.error(res.data.message, { toastId: 'success1' });
    }
};

export { handleApiResponse };
