import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { useDispatch, useSelector } from 'react-redux';
import { sendRequest } from '../../../apis/APIs';
import { handleApiResponse } from '../../../apis/apiUtils';
import { toast } from 'react-toastify';
import appRoutes from '../../../configs/AppRoutes';
import BackButton from '../../../common/BackButton';
import Dropdown from 'react-bootstrap/Dropdown';
import moment from 'moment';
import appSettings from '../../../configs/AppConfig';
import Pagination from '../../../common/Pagination/pagination';
import { Helmet } from 'react-helmet';


const Detail = () => {
    const { isLoggedIn } = useSelector((state) => state.common);
    const { id } = useParams();
    const dispatch = useDispatch();
    const [carrierDetail, setCarrierDetail] = useState({});
    const [paginationData, setPaginationData] = useState({ perpage: appSettings.recordsPerPageTable, page: 1, totalCount: 0 });
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            getCarrierDetail(1);
        }
    }, []);

    const getCarrierDetail = async (page) => {
        try {
            dispatch(callCommonAction({ loading: true, breadCrumb: ['Carrier', 'Detail', appRoutes.adminCarrierListRoute] }));
            const res = await sendRequest(`/carrier/detail/${id}`, 'GET', { page: page, limit: paginationData.perpage });
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                setCarrierDetail(res.data.data);
                setPaginationData({ ...paginationData, totalCount: res.data.data?.users?.totalDocs, page: res.data.data?.users?.page });
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    const renderTabeBody = () => {
        return carrierDetail?.users?.docs?.map((user) => {
            let badgeCls = user.status === 1 ? 'success' : 'danger';
            return (
                <tr key={user.id}>
                    <td className="d-flex align-items-center">
                        {/**begin:: Avatar **/}
                        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                            <a href={undefined}>
                                <div className="symbol-label">
                                    <img src={user && user.user_image ? process.env.REACT_APP_ASSET_ENDPOINT + '/' + user.user_image : '/assets/media/avatars/blank.png'} alt="Emma Smith" className="w-100" />
                                </div>
                            </a>
                        </div>
                        {/**end::Avatar**/}
                        {/**begin::User details**/}
                        <div className="d-flex flex-column">
                            <a href={undefined}  onClick={() => navigate(appRoutes.adminUserDetailRoute + '/' + user._id)} className="text-gray-800 text-hover-primary mb-1">{user.display_name}</a>
                            <span>{user.email}</span>
                        </div>
                        {/**begin::User details**/}
                    </td>
                    <td>{moment(new Date(user.created_at)).format('MM/DD/YYYY')}</td>
                    <td ><span className={`text-uppercase badge badge-light-${badgeCls}`}>{user.status_name}</span></td>
                    <td className="text-end">
                        <Dropdown>
                            <Dropdown.Toggle className='btn btn-light btn-active-light-primary btn-sm' id="dropdown-basic">
                                Actions
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href={undefined} onClick={() => navigate(appRoutes.adminUserDetailRoute + '/' + user._id)}>View</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            );
        });

    };

    return (
        <>

            <Helmet>
                <title>iQuote | Carrier Detail</title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div id="kt_content_container" className="container-xxl">
                    <BackButton />
                    {/** Detail page START **/}
                    <div className="card mb-0" id="kt_profile_details_view">
                        <div className="card-header align-items-center">
                            <div className="card-title m-0">
                                <h3 className="fw-bold m-0">{carrierDetail?.carrier?.label}</h3>
                            </div>
                            <div className={`text-uppercase ms-auto badge ${carrierDetail?.carrier?.status === 1 ? "badge-light-success" : "badge-light-danger"}`}>
                                <a>{carrierDetail?.carrier?.status === 1 ? 'Active' : 'Inactive'}</a>
                            </div>
                        </div>
                    </div>
                    {/**begin::Card**/}
                    <div className="card mt-8">
                        {/**begin::Card header**/}
                        <div className="card-header">
                            {/**begin::Card title**/}
                            <div className="card-title m-0"><h3 className="fw-bold m-0">Associated agent record</h3></div>
                        </div>
                        {/**end::Card header**/}
                        {/**begin::Card body**/}
                        <div className="card-body table-frame py-4">
                            {/**begin::Table**/}
                            <div className='table-responsive'>
                            <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users">
                                <thead>
                                    <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                        <th className="min-w-125px">Agent</th>
                                        <th className="min-w-125px">Joined Date</th>
                                        <th className="min-w-125px">Status</th>
                                        <th className="text-end min-w-100px">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="text-gray-600 fw-semibold">
                                    {carrierDetail?.users?.docs?.length > 0 ? renderTabeBody() : <tr>
                                        <td colSpan={4} className='text-center'>No result found!</td>
                                    </tr>}
                                </tbody>
                            </table>
                            </div>
                            {/**end::Table**/}
                            <Pagination className="pagination-bar" currentPage={paginationData.page} totalCount={paginationData.totalCount}
                                pageSize={paginationData.perpage} onPageChange={page => getCarrierDetail(page)}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Detail;
