import STRINGS from '../../../common/strings/strings';

export function validateNewsFormData(data, fieldName,editorContent) {
    const { name, des,user_image} = data; 
   
    let errorMsg = ''; let errors = {}; let formVaildCheck = true;
    if (name === '' && (fieldName == 'name' || fieldName == '')) {
        errorMsg = name === '' ? STRINGS.nameReq : '';
        errors.name = errorMsg;
        formVaildCheck = false;
    }
    if (editorContent === '' ) {
        errorMsg = des === '' ? STRINGS.product.descriptionReq : '';
        errors.des = errorMsg;
        formVaildCheck = false;
    }

    if (user_image === '' || user_image === null ) { 
        
        errorMsg = user_image === '' ? STRINGS.product.userimageReq : '';
        errors.user_image = errorMsg;
        formVaildCheck = false;
    }

    return { formVaildCheck: formVaildCheck, errors: errors };
}