// common/apiHelper.js
import { toast } from 'react-toastify'; // then this
const uploadImage = async (maxSizeMB, allowedFormats, file) => {
  try {
    // Validate file size
    if (file.size > maxSizeMB * 1024 * 1024) {
      toast.error('File size exceeds ' + maxSizeMB + ' MB limit.');
      return false;
    }
    const allowedFormatsRegex = new RegExp(`(${allowedFormats.join('|')})$`, 'i');
    if (!allowedFormatsRegex.test(file.type)) {
      toast.error('Invalid file format. Please use ' + allowedFormats.join(', '));
      return false;
    }
    return true;
  } catch (error) {
    // Handle errors
    console.error('Error uploading image:', error);
    throw error;
  }
};

export { uploadImage };
